export const b2cSimilarCarPageEvents = {
    "viewSimilarCarCard": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Similar_page",
        "eventAction": "Detailpage_allSimilarCar"
    },
    "similarCarClickTDSAllCar": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Similar_page",
        "eventAction": "similar_car_click_TDS_All_car"
    },
    "addToWishlistFromSimilarCars": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Similar_page",
        "eventAction": "add_to_wishlist"
    },
    "handleBackCta": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Similar_page",
        "eventAction": "similar_back"
    },
    "similarCarClickDPAllCar": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Similar_page",
        "eventAction": "similar_car_click_DP_All_car"
    }
};

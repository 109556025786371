import React, { Fragment } from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Icons
import CloseButton from "../icon-cross";

// Constants
import { CURRENCY } from "../../../constants/app-constants";

// Utils
import { getAppliedFilterTagsName } from "../../../utils/helpers/applied-filter-tags";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";

const AppliedFilterTagV2 = ({
    filter = { },
    allFilters = { },
    onRemove,
    showClose = true,
    text = "",
    showUnit = true,
    currency = CURRENCY.IN,
    isDesktopView = false
}) => {
    const { filterKey, optionKey, subOptionKey } = filter || { };
    const filterText = text || getAppliedFilterTagsName(filter, allFilters, showUnit, currency, isDesktopView);

    const onRemoveFilter = async () => {
        await yieldToMainThread();
        onRemove({
            filterKey,
            optionKey,
            subOptionKey,
            isSelected: false
        });
    };

    return (
        <Fragment>
            {filterText && (
                <div styleName="styles.filterApplied2" >
                    <span>
                        {filterText}
                        {showClose && <CloseButton onClickHandler={onRemoveFilter} type="blueFilter" />}
                    </span>
                </div>
            )}
        </Fragment>
    );
};

AppliedFilterTagV2.propTypes = {
    filter: PropTypes.object,
    allFilters: PropTypes.object,
    onRemove: PropTypes.func,
    showClose: PropTypes.bool,
    text: PropTypes.string,
    showUnit: PropTypes.bool,
    currency: PropTypes.string,
    isDesktopView: PropTypes.bool
};

export default AppliedFilterTagV2;

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FilterSelection from "./component";
import {updateSelectedOnScreenFilter } from "../filters/actions";

const mapStateToProps = ({
    filters: {
        allFilters,
        onScreenFiltersList,
        selectedOnScreenFilter
    }
}) => ({
    allFilters,
    onScreenFiltersList,
    selectedOnScreenFilter
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSelectedOnScreenFilterConnect: updateSelectedOnScreenFilter
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelection);

/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Styles
import styles from "./styles.css";

// Component
import AppliedFiltersV2 from "../applied-filters-v2";

// Utilities
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { minWidthFilters } from "../car-listing/constants";
import saveCookie from "../../../utils/helpers/save-cookie";

// Constants
import { COOKIE_CONSTANTS, NUMBER } from "../../../constants/app-constants";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";

const FilterSelection = ({
    allFilters,
    selectedOnScreenFilter,
    updateSelectedOnScreenFilterConnect,
    onScreenFiltersList
}) => {
    const onFilterClick = async (filter) => {
        const selectedFilter = filter.key;
        await yieldToMainThread();
        if (selectedOnScreenFilter === selectedFilter) {
            updateSelectedOnScreenFilterConnect("");
        } else {
            updateSelectedOnScreenFilterConnect(selectedFilter);
        }
        await yieldToMainThread();
        saveCookie(COOKIE_CONSTANTS.LISTING_FILTER_NUDGE_SHOWN, true, NUMBER.SEVEN);
        trackPlatformCustomEventsAEV2(b2cListingPageEvents.filterBucket, {
            eventLabel: filter.displayText
        });
    };

    return (
        <React.Fragment>
            <AppliedFiltersV2 />
            {
                (onScreenFiltersList || []).map((filter) => {
                    return allFilters[filter] && filter !== "savedFilters" ? (
                        <li
                            key={filter}
                            onClick={onFilterClick.bind(null, allFilters[filter])}
                            styleName={`${selectedOnScreenFilter === filter ? `styles.active` : ``}`}>
                            <span style={{ minWidth: minWidthFilters[filter] || "auto" }}>{allFilters[filter].displayText}</span>
                        </li>

                    ) : null;
                })
            }
        </React.Fragment>
    );
};

FilterSelection.propTypes = {
    allFilters: PropTypes.object,
    selectedOnScreenFilter: PropTypes.string,
    updateSelectedOnScreenFilterConnect: PropTypes.func,
    onScreenFiltersList: PropTypes.array
};

export default withRouter(FilterSelection);

import React from "react";

import CarListing from "../../../components/ae.mobile/car-listing";

const CarListingPage = (props) => {

    return <CarListing {...props}/>;
};

CarListingPage.propTypes = {};

export default CarListingPage;

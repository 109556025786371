import { b2cDetailPageEvents } from "../../../tracking/msite/b2c-detail-page-events";
import { b2cHomePageEvents } from "../../../tracking/msite/b2c-home-page-events";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";

export const SOURCE = {
    MY_BOOKINGS: "MY_BOOKINGS",
    LISTING: "LISTING",
    WISHLIST: "WISHLIST",
    WISHLIST_SIMILAR_CARS: "WISHLIST_SIMILAR_CARS",
    SIMILAR_CARS: "SIMILAR_CARS",
    RECENT_CARS: "RECENT_CARS",
    FEATURED_CARS: "FEATURED_CARS"
};

export const BOOKING_STATE = {
    CANCEL: "CANCEL",
    EXPIRED: "EXPIRED",
    BOOKED: "BOOKED",
    CREATED: "CREATED",
    RETURNED: "RETURNED"
};

export const SOURCE_EVENT_DATA = {
    LISTING: {
        eventName: b2cListingPageEvents.addToWishlistListingPage,
        addEventLabel: "Add to wishlist - Listing page",
        removeEventLabel: "Remove from wishlist - Listings page"
    },
    SIMILAR_CARS: {
        eventName: b2cDetailPageEvents.addToWishlistDetailPage,
        eventLabel: "Add to wishlist - Similar car",
        removeEventLabel: "Remove from wishlist - Similar car"
    },
    FEATURED_CARS: {
        eventName: b2cHomePageEvents.addToWishlistHomePage,
        eventLabel: "Add to wishlist - Featured car",
        removeEventLabel: "Remove from wishlist - Featured car"
    }
};

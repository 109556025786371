import { b2cFeedbackPageEvents } from "../../../tracking/msite/b2c-feedback-page-events";
import { getPostBcCustomUrl } from "../../../utils/helpers/get-postbc-url";

export const SOURCE_MAP = {
    postbc: "Post BC",
    listing: "Listing"
};

export const FEEDBACK_TYPE = {
    POST_BC: "post-bc",
    TEST_DRIVE: "test-drive",
    DELIVERY: "delivery"
};

export const NPS_FEEDBACK_TYPE = {
    TEST_DRIVE: "test-drive-nps"
};

export const FEEDBACK_TYPE_NPS_MAP = {
    "test-drive": "test-drive-nps"
};

export const FEEDBACK_CONFIG = {
    [FEEDBACK_TYPE.POST_BC]: {},
    [FEEDBACK_TYPE.TEST_DRIVE]: {
        title: "Test Drive Feedback",
        loginRequired: true,
        cookieName: "hide-td-feedback",
        navigateTo: ({ appointmentId }) => {
            const { relativeURL: postBcRelativeUrl } = getPostBcCustomUrl({ lid: appointmentId });
            return postBcRelativeUrl;
        },
        gaEvents: {
            cross: b2cFeedbackPageEvents.crossTDFeedback,
            submit: b2cFeedbackPageEvents.submitTDFeedback
        }
    },
    [FEEDBACK_TYPE.DELIVERY]: {
        title: "DELIVERY FEEDBACK",
        navigateTo: ({ appointmentId, source }) => {
            if (source === "postbc") {
                const { relativeURL: postBcRelativeUrl } = getPostBcCustomUrl({ lid: appointmentId });
                return postBcRelativeUrl;
            }
            return `/`;
        },
        loginRequired: false,
        gaEvents: {}
    }
};

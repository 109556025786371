/* eslint-disable no-unused-expressions */
/* eslint-disable max-params */
// Utils
import gaId from "../../utils/helpers/get-ga-from-cookie";
import { formatDate, DATE_FORMAT_TYPE } from "../../utils/helpers/get-day-from-date";
import { getAppliedFilterTagsName } from "../../utils/helpers/applied-filter-tags";

// Constants
import { CURRENCY } from "../../constants/app-constants";
import { FILTER_ENTITIES } from "../../utils/filters-v2/constants";

export const searchResultTrackingDataParser = (carListResponse = {}, appliedFilters = [], allFilters = {}, otherProperties = {}) => {
    const { results = [], selectedFilters = {} } = carListResponse || {};
    const apptListResult = (results || [])?.map((item) => item?.appointmentId) || [];

    const appliedFilterTags = [];
    if (selectedFilters?.[FILTER_ENTITIES.MAKE]) {
        (selectedFilters?.[FILTER_ENTITIES.MAKE]?.buckets || [])?.forEach((item) => {
            if (item?.isSubFacetFullySelected) {
                appliedFilterTags.push(`make:${item?.name}`);
            } else {
                (item?.subFacet?.buckets || [])?.forEach((model) => {
                    appliedFilterTags.push(`model:${item?.name} ${model?.name}`);
                });
            }
        });
    }

    (appliedFilters || [])?.forEach((appliedFilter) => {
        if (appliedFilter?.filterKey !== FILTER_ENTITIES.MAKE) {
            const filterName = getAppliedFilterTagsName(appliedFilter, allFilters, true, CURRENCY.AE, false) || "";
            appliedFilterTags.push(`${appliedFilter?.filterKey}:${filterName}`);
        }
    });

    const eventData = {
        userId: gaId || "",
        result: apptListResult,
        filtername: (appliedFilterTags || [])?.join(" | "),
        timestamp: formatDate(new Date(), DATE_FORMAT_TYPE.DATE_MONTH_YEAR_TIME),
        typedQuery: "",
        searchQuery: "",
        filterType: "",
        ...otherProperties
    };

    return eventData;
};

/* eslint-disable complexity */
import React, { useState } from "react";

import styles from "./styles.css";

import PropTypes from "prop-types";

// Images
import ImageBanner from "./images/sale.png";
import IconClose from "./images/close.svg";

//Components
import Modal from "../../shared/modal";
import Button from "../../shared/button";
import Login from "../login";

//Utils
import Filters from "../../../utils/filters-v2";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
// import scrollToTop from "../../../utils/helpers/scroll-to-top";

//Constants
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import { SOURCES as LOGIN_SOURCES } from "../login/constants";
import { LOGIN_TITLE } from "../../../constants/app-constants";

const SaleLiveNotifierPopup = ({
    activeSaleConfig,
    onClose = () => { },
    selectedCity,
    updateFilterOptionDataConnect,
    allFilters,
    isLoggedIn,
    reloadCarListConnect }) => {

    const {
        active: isSaleActive = false,
        saleDetails = {},
        loginMandatory = false
    } = activeSaleConfig;

    const { saleLiveNotifierPopupInfo = {} } = saleDetails || {};
    const [showLogin, setShowLogin] = useState(false);

    const { customPush } = useCustomHistoryOperations();

    const { headerText, headerSubText, description = {}, additionalData = {} } = saleLiveNotifierPopupInfo || {};
    const { imageUrl = "", backgroundGradients, ctaText = "", imageWidth = "225", imageHeight = "225" } = additionalData || {};
    const inlineStyles = backgroundGradients && { backgroundImage: `linear-gradient(${backgroundGradients})` };
    const { heading = "", body = [] } = description || {};

    if (!isSaleActive) {
        return null;
    }

    const applyDiscountFilter = async () => {
        setShowLogin(false);
        const selectedFilterData = allFilters[FILTER_ENTITIES.DISCOUNT];
        const selectedFilterOption = selectedFilterData?.options?.find(option => option?.key === "true");
        const selectedFiltersPayload = {
            filterKey: selectedFilterData.key,
            isSelected: true,
            optionKey: selectedFilterData.key,
            displayText: selectedFilterOption?.displayText
        };
        const filters = new Filters({ cityCode: selectedCity?.code, cityName: selectedCity?.name });
        const { relativeURL } = filters.getListingPayload({ selectedFilters: [selectedFiltersPayload], filters: allFilters });

        updateFilterOptionDataConnect(selectedFiltersPayload);
        reloadCarListConnect(true);
        // scrollToTop();
        await customPush(relativeURL);
        onClose();
    };

    const onCtaClick = () => {

        if (loginMandatory && !isLoggedIn) {
            setShowLogin(true);
        } else {
           applyDiscountFilter();
        }

    };

    return (
        <Modal isOpen={true} close={onClose}>
            <Login isVisible={showLogin} loginFor={LOGIN_SOURCES.SALE_BANNER_LISTING} loginSource={LOGIN_TITLE.ACTIVE_SALE} onClose={setShowLogin.bind(null, false)} onSuccess={applyDiscountFilter}>
                <div styleName={"styles.wrapper"} style={inlineStyles}>
                    <img styleName={"styles.popup-close"} src={IconClose} onClick={onClose} />
                    <article>
                        <h3>{headerText}</h3>
                        <h3>{headerSubText}</h3>
                        <figure>
                            {imageUrl ? <img src={imageUrl} alt="" width={imageWidth} height={imageHeight} /> : <img src={ImageBanner} alt="" width={225} height={225} />}
                        </figure>
                        <div styleName={"styles.description"} >
                            {heading && <div styleName={"styles.heading"}>{heading}</div>}
                            {body?.length > 0 && body.map((bodyText, index) => {
                                const { content } = bodyText || {};
                                return (<div styleName={"styles.bodyText"} key={index}>{content}</div>);
                            })}
                        </div>
                        {ctaText && <div styleName={"styles.buttonWrap"}><Button text={ctaText} onClick={onCtaClick} /> </div>}
                    </article>
                </div>
            </Login>
        </Modal>
    );
};

SaleLiveNotifierPopup.propTypes = {
    activeSaleConfig: PropTypes.object,
    onClose: PropTypes.func,
    selectedCity: PropTypes.object,
    reloadCarListConnect: PropTypes.func,
    updateFilterOptionDataConnect: PropTypes.func,
    allFilters: PropTypes.object,
    isLoggedIn: PropTypes.bool
};

export default SaleLiveNotifierPopup;

import React, { Fragment } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Constants
import { NUMBER } from "../../../constants/app-constants";

const ShowHeader = ({ totalCars, text, onChange = () => {} }) => {
    return (
        <Fragment>
            <div styleName={"styles.header"} onClick={onChange}>
                <h1 styleName={"styles.heading"}>{totalCars > NUMBER.ZERO && <span>{totalCars}</span>} {text}</h1>
            </div>
        </Fragment>
    );
};

ShowHeader.propTypes = {
    text: PropTypes.string,
    onChange: PropTypes.func,
    totalCars: PropTypes.number
};

export default ShowHeader;

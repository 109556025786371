import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OnScreenFilter from "./component";

const mapStateToProps = ({
    filters: {
        selectedOnScreenFilter
    },
    experiments: { msiteSaveSelectedFilters } = {}
}) => ({
    selectedOnScreenFilter, msiteSaveSelectedFilters
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OnScreenFilter);

import { CrmService } from "../../../service";
import Types from "./types";
import { HTTP_CODES, SENTRY_ERRORS } from "../../../constants/app-constants";
import { TOAST_MESSAGE, TOAST_VARIANT } from "../../shared/toast-message/constant";
import { showToast } from "../../shared/toast-message/actions";
import { isValidOrderId } from "../../../utils/validators/car-details-validator";
import { captureMessage } from "@sentry/browser";

const feedbackSuccessToastOptions = {
    variant: TOAST_VARIANT.SUCCESS,
    text: TOAST_MESSAGE.feedbackSuccess,
    toastStyle: "feedbackSuccess"
};

const setFeedbackCarDetailContent = (data) => ({
    type: Types.SET_CAR_DETAIL_CONTENT,
    data
});

const fetchFeedback = ({ orderId, feedbackType }, token) => () => {
    return new Promise((resolve, reject) => {
        if (isValidOrderId(orderId)) {
            CrmService.getFeedback({ orderId, feedbackType }, token)
                .then(response => {
                    const { statusCode, status, data } = response?.data;
                    if (statusCode === HTTP_CODES.SUCCESS && status === "success") {
                        resolve(data);
                    } else {
                        resolve({});
                    }
                }).catch(error => reject(error));
        } else {
            captureMessage(SENTRY_ERRORS.INVALID_ORDER_ID, "info");
            reject({ error: "Invalid Order ID" });
        }
    });
};

//get feedback NPS status
const fetchClientNPSFeedbackStatus = ({ orderId, feedbackType }, token) => () => {
    return new Promise((resolve, reject) => {
        if (isValidOrderId(orderId)) {
            CrmService.getClientNPSFeedbackStatusV4({ orderId, feedbackType }, token)
                .then(response => {
                    const { statusCode, status, data } = response?.data;
                    if (statusCode === HTTP_CODES.SUCCESS && status === "success") {
                        resolve(data);
                    } else {
                        resolve({});
                    }
                }).catch(error => reject(error));
        } else {
            // captureMessage(SENTRY_ERRORS.INVALID_ORDER_ID, Severity.Info);
            reject({ error: "Invalid Order ID" });
        }
    });
};

// get Questionnaire for NPS
const fetchNPSQuestionnaire = (feedbackType, token) => () => {
    return new Promise((resolve, reject) => {
        CrmService.getClientNPSQuestionsV4(feedbackType, token).then(response => {
            const { tdNpsFeedback } = response?.data;
            resolve(tdNpsFeedback);
        }).catch(error => reject(error));
    });
};

const submitClientFeedbackV4 = ({ feedbackType, orderId, payload }, token) => () => {
    return new Promise((resolve, reject) => {
        CrmService.submitClientFeedbackV4({ feedbackType, orderId, payload }, token).then(response => {
            const { statusCode, status, data } = response?.data;
            if (statusCode === HTTP_CODES.SUCCESS && status === "success") {
                resolve(data);
            } else {
                reject({});
            }
        }).catch(error => reject(error));
    });
};

const fetchUserFeedback = ({ orderId, feedbackType }, token) => () => {
    return new Promise((resolve, reject) => {
        CrmService.getUserFeedback({ orderId, feedbackType }, token)
            .then(response => {
                const { statusCode, status, data } = response?.data;
                if (statusCode === HTTP_CODES.SUCCESS && status === "success") {
                    resolve(data);
                } else {
                    resolve({});
                }
            }).catch(error => reject(error));
    });
};

const fetchFeedbackOptions = ({ feedbackType }, token) => () => {
    return new Promise((resolve, reject) => {
        CrmService.getFeedbackOptions({ feedbackType }, token)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const fetchClientFeedback = ({ orderId, feedbackType }) => () => {
    return new Promise((resolve, reject) => {
        if (isValidOrderId(orderId)) {
            CrmService.getClientFeedback({ orderId, feedbackType })
                .then(response => {
                    const { statusCode, status, data } = response?.data;
                    if (statusCode === HTTP_CODES.SUCCESS && status === "success") {
                        resolve(data);
                    } else {
                        resolve({});
                    }
                    resolve(response.data);
                }).catch(error => reject(error));
        } else {
            captureMessage(SENTRY_ERRORS.INVALID_ORDER_ID, "info");
            reject({ error: "Invalid Order ID" });
        }
    });
};

const fetchClientFeedbackOptions = ({ feedbackType }) => () => {
    return new Promise((resolve, reject) => {
        CrmService.getClientFeedback({ feedbackType })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const saveFeedback = (payload, { orderId, feedbackType }, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CrmService.postFeedback(payload, { orderId, feedbackType }, token)
            .then(response => {
                dispatch(showToast(feedbackSuccessToastOptions));
                resolve(response);
            }).catch(error => reject(error));
    });
};

const saveClientFeedback = (payload, { orderId, feedbackType }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CrmService.postClientFeedback(payload, { orderId, feedbackType })
            .then(response => {
                dispatch(showToast(feedbackSuccessToastOptions));
                resolve(response);
            })
            .catch(error => reject(error));
    });
};

const updateFeedback = (payload, { orderId, feedbackType }, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CrmService.putFeedback(payload, { orderId, feedbackType }, token)
            .then(response => {
                dispatch(showToast(feedbackSuccessToastOptions));
                resolve(response);
            }).catch(error => reject(error));
    });
};

const updateClientFeedback = (payload, { orderId, feedbackType }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CrmService.putClientFeedback(payload, { orderId, feedbackType })
            .then(response => {
                dispatch(showToast(feedbackSuccessToastOptions));
                resolve(response);
            })
            .catch(error => reject(error));
    });
};

export {
    fetchFeedback,
    fetchFeedbackOptions,
    fetchClientFeedback,
    fetchClientFeedbackOptions,
    fetchUserFeedback,
    saveFeedback,
    saveClientFeedback,
    setFeedbackCarDetailContent,
    updateFeedback,
    updateClientFeedback,
    fetchClientNPSFeedbackStatus,
    fetchNPSQuestionnaire,
    submitClientFeedbackV4
};

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SaleLiveNotifierPopup from "./component";
import { updateFilterOptionData } from "../filters/actions";
import { reloadCarList } from "../car-listing/actions";

const mapStateToProps = ({
    config: {
        activeSaleConfig
    },
    filters: {
        allFilters,
        selectedFilters
    },
    cities: {
        selectedCity
    },
    user: {
        isLoggedIn
    }
}) => ({
    activeSaleConfig,
    selectedCity,
    allFilters,
    selectedFilters,
    isLoggedIn
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList,
    updateFilterOptionDataConnect: updateFilterOptionData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SaleLiveNotifierPopup);


import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import LoanBudgetCta from "./component";

// Actions
import { updateFilterOptionData, updateAppliedFilters } from "../filters/actions";
import { reloadCarList } from "../car-listing/actions";

const mapStateToProps = ({
    filters: {
        allFilters,
        selectedFilters,
        appliedFilters
    },
    cities: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName
        } = {}
    },
    loanEligibility
}) => ({
    allFilters,
    selectedFilters,
    appliedFilters,
    selectedCityCode,
    selectedCityName,
    loanEligibility
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateFilterOptionDataConnect: updateFilterOptionData,
    updateAppliedFiltersConnect: updateAppliedFilters,
    reloadCarListConnect: reloadCarList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoanBudgetCta);

import React from "react";
import Statsig from "statsig-js";
import getMeaningfulExperimentNames from "../utils/statsig-ab-utils/get-meaningful-experiment-names";
import { useSelector, useDispatch } from "react-redux";
import { setExperiment as setExperimentConnect } from "../components/shared/ab-experiment/actions";
const useExperiments = ({ experimentKeys }) => {
    const { experiments: allExperiments } = useSelector(store => store);
    const { statsigInitialized = false } = allExperiments || {};
    const dispatch = useDispatch();
    const getExperiments = () => {
        experimentKeys.forEach((experimentName) => {
            if (!allExperiments.hasOwnProperty(getMeaningfulExperimentNames(experimentName))) {
                const experimentData = Statsig.getExperiment(experimentName);
                const { value } = experimentData || {};
                const { key = "" } = value || {};
                dispatch(setExperimentConnect(getMeaningfulExperimentNames(experimentName), key, true));
            }
        });
    };
    React.useEffect(() => {
        if (statsigInitialized) {
            getExperiments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statsigInitialized]);
};
export default useExperiments;

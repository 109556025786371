import React from "react";
import styles from "./skeleton.css";

const Skeleton = () => {
    return (
        <React.Fragment>
            <div styleName={"styles.headerShimmerWrap"}>
                <div className="shimmer" styleName={"styles.header"} />
                <div className="shimmer" styleName={"styles.sort"} />
            </div>
            <div className="shimmer" styleName={"styles.para"} />
            {/*  <div className="shimmer" styleName={"styles.readMore"} /> */}
        </React.Fragment>
    );
};

export default Skeleton;

/* eslint-disable complexity */
/* eslint-disable max-statements */
export const deepCompareRecursive = (obj1, obj2) => {
    if (obj1 === obj2 || Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
      }

      // Use a Set to store encountered keys for obj1
      const encounteredKeys = new Set();

      for (const key in obj1) {
        if (obj1.hasOwnProperty(key)) {
          encounteredKeys.add(key);
          const value1 = obj1[key];
          const value2 = obj2[key];
          const type1 = typeof value1;
          const type2 = typeof value2;

          if (type1 !== type2 || (typeof value1 === "object" && value1 !== null && typeof value2 === "object" && value2 !== null)) {
            // Recursively compare if both values are objects
            return deepCompareRecursive(value1, value2);
          } else if (!encounteredKeys.has(key) || value1 !== value2) {
            // Key not found in obj2 or values differ
            return false;
          }
        }
      }

      // Check for remaining keys in obj2 (might not be present in obj1)
      for (const key in obj2) {
        if (!encounteredKeys.has(key)) {
          return false;
        }
      }
    return true; // Same structure and values
  };

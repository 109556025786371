export const SortIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3997_4312)">
<path d="M10.6667 11.34V7.33334C10.6667 6.96667 10.3667 6.66667 10 6.66667C9.63335 6.66667 9.33335 6.96667 9.33335 7.33334V11.34H8.14002C7.84002 11.34 7.69335 11.7 7.90669 11.9067L9.76669 13.76C9.90002 13.8867 10.1067 13.8867 10.24 13.76L12.1 11.9067C12.3134 11.7 12.16 11.34 11.8667 11.34H10.6667V11.34ZM5.76669 2.23334L3.90669 4.09334C3.69335 4.3 3.84002 4.66 4.14002 4.66H5.33335V8.66667C5.33335 9.03334 5.63335 9.33334 6.00002 9.33334C6.36669 9.33334 6.66669 9.03334 6.66669 8.66667V4.66H7.86002C8.16002 4.66 8.30669 4.3 8.09335 4.09334L6.23335 2.23334C6.10669 2.10667 5.89335 2.10667 5.76669 2.23334Z" fill="#0032A6"/>
</g>
<defs>
<clipPath id="clip0_3997_4312">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>`;

export const CaretDownIcon = `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="arrow_drop_down" clip-path="url(#clip0_1640_2228)">
<path id="Vector" d="M4.35503 5.855L5.65003 7.15C5.84503 7.345 6.16003 7.345 6.35503 7.15L7.65003 5.855C7.96503 5.54 7.74003 5 7.29503 5H4.70503C4.26003 5 4.04003 5.54 4.35503 5.855Z" fill="#0032A6"/>
</g>
<defs>
<clipPath id="clip0_1640_2228">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg>`;

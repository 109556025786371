import React from "react";
import styles from "./skeleton.css";

const Skeleton = () => {
    return (
        <ul styleName={"styles.filterShimmerWrap"}>
            <li className="shimmer" />
            <li className="shimmer" />
            <li className="shimmer" />
            <li className="shimmer" />
            <li className="shimmer" />
            <li className="shimmer" />
            <li className="shimmer" />
        </ul>
    );
};

export default Skeleton;

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppliedFiltersV2 from "./component";
import { updateFilterOptionData, updateSelectedSuggestions, updateAppliedSuggestions, updateAppliedFilters } from "../filters/actions";
import { reloadCarList, resetCarList } from "../car-listing/actions";
import { setComparingActive } from "../compare-widget/actions";

const mapStateToProps = ({
    filters: {
        allFilters,
        appliedSuggestions,
        appliedFilters,
        selectedSort,
        urlOptions
    },
    cities: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName
        } = {}
    }
}) => ({
    allFilters,
    selectedCityName,
    selectedCityCode,
    appliedSuggestions,
    appliedFilters,
    selectedSort,
    urlOptions
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateFilterOptionDataConnect: updateFilterOptionData,
    resetCarListConnect: resetCarList,
    updateSelectedSuggestionsConnect: updateSelectedSuggestions,
    updateAppliedSuggestionsConnect: updateAppliedSuggestions,
    updateAppliedFiltersConnect: updateAppliedFilters,
    reloadCarListConnect: reloadCarList,
    setComparingActiveConnect: setComparingActive
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppliedFiltersV2);

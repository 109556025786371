/* eslint-disable complexity */
import React, { Fragment, useMemo, useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

//Utils
import { parseSeasonalSaleData } from "../../../utils/helpers/parse-seasonal-sale";
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";

//Components
import SaleLiveNotifierPopup from "../sale-live-notifier-popup";

//Constants
import { NUMBER } from "../../../constants/app-constants";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";

const SeasonalSaleListingBanner = ({
    seasonalSale,
    isloginMandatoryForSale = false,
    isLoggedIn,
    superAppConfig
}) => {

    const [showSaleNotifierPopup, setShowSaleNotifierPopup] = useState(false);

    const parsedSaleData = useMemo(() => parseSeasonalSaleData(seasonalSale), [seasonalSale]) || {};
    const { additionalData = {}, listingBannerPosition } = seasonalSale || {};

    const { imageUrl = "" } = additionalData || {};
    const { backgroundGradients = "" } = additionalData || {};
    const inlineStyles = backgroundGradients ? { backgroundImage: `linear-gradient(${backgroundGradients})` } : {};

    const { title, subTitle, description } = parsedSaleData || {};

    const onLoginClick = () => {
        trackPlatformCustomEventsAEV2(b2cListingPageEvents.saleBannerLoginClickListingPage);
        setShowSaleNotifierPopup(true);
    };

    return (
        <Fragment>
            {listingBannerPosition < NUMBER.FOUR && !superAppConfig?.topBanner?.showTopBannerLp  && (
                <Helmet>
                    <link rel="preload" href={imageUrl} as={"image"} type="image/webp" fetchPriority="high" />
                </Helmet>
            )}
            <div styleName={"styles.saleCardWrapper"} style={inlineStyles}>
                <div styleName={"styles.flexRow styles.bannerContent"}>
                    <div styleName={"styles.content"}>
                        <h3 dangerouslySetInnerHTML={{ __html: title || "" }} />
                        {(!isloginMandatoryForSale || isLoggedIn) && <p dangerouslySetInnerHTML={{ __html: subTitle || "" }} />}
                        <ul>
                            {
                                (description || []).map((item, index) => {
                                    return <li key={index} dangerouslySetInnerHTML={{ __html: item || "" }} />;
                                })
                            }
                        </ul>
                        {isloginMandatoryForSale && !isLoggedIn && <span styleName={"styles.loginCta"} onClick={onLoginClick}>View Details</span>}
                    </div>
                    <div styleName={"styles.banner"}>
                        <img src={imageUrl} alt="" width={121} height={83} />
                    </div>
                </div>
            </div>
            {showSaleNotifierPopup && <SaleLiveNotifierPopup onClose={() => setShowSaleNotifierPopup(false)} />}
        </Fragment>
    );

};

SeasonalSaleListingBanner.propTypes = {
    seasonalSale: PropTypes.object,
    superAppConfig: PropTypes.object,
    isloginMandatoryForSale: PropTypes.bool,
    isLoggedIn: PropTypes.bool
};

export default SeasonalSaleListingBanner;

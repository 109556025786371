/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";
// Styles
import styles from "./styles.css";

// Component
import Login from "../login";
import withWishlist from "../with-wishlist";
import withLogin from "../with-login";
import FilterSelection from "../filter-selection";
import SortListingHeader from "../sort-listing-header";
import PageLoader from "../../shared/page-loader";

// Constant
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import { COOKIE_CONSTANTS, NUMBER } from "../../../constants/app-constants";
import { DEFAULT_SORT } from "../car-list/constants";

// Icons
import Compare from "./images/compare-icon.svg";
import CloseButton from "../../shared/icon-cross";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import Skeleton from "./skeleton";

// Utils
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import saveCookie from "../../../utils/helpers/save-cookie";
import useSwipeDetect from "../../../hooks/use-swipe-detect";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { FilterIcon } from "./img-constants";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";
import { b2cAllPageEvents } from "../../../tracking/msite/b2c-all-page-events";

const DEFAULT_SORT_KEY = "Sort";

const CompareCheckboxButton = ({
    isComparingActive,
    onClick,
    totalSelectedCarsToCompare
}) => {
    const ctaText = totalSelectedCarsToCompare ? `Compare ${isComparingActive ? `(${totalSelectedCarsToCompare})` : ""}` : "Compare";
    const [checked, setChecked] = useState(isComparingActive);

    const handleClick = async() => {
        setChecked(!checked);
        await yieldToMainThread();
        onClick(!checked);
    };

    useEffect(() => {
        setChecked(isComparingActive);
    }, [isComparingActive]);

    return (
        <li styleName={`${checked ? "styles.compareSelected" : ""}`} onClick={handleClick}>
            <div styleName={"styles.compareIcon"}>
                <img src={Compare} height={16} width={16} alt="Compare Icon" />
            </div>
            {ctaText}
            {checked && <span styleName={"styles.closeCompareIcon"}><CloseButton type="blueFilter" /></span>}
        </li>
    );
};
const FilterDropdown = ({
    selectedOnScreenFilter,
    updateSelectedOnScreenFilterConnect,
    isTabNavTriggered,
    onFilterSelection = () => { },
    showWishlistPopupConnect,
    setShowLogin,
    showLogin,
    appliedFilters,
    isComparingActive,
    setComparingActiveConnect,
    totalSelectedCarsToCompare,
    filteredListingCount,
    selectedSort,
    location,
    selectedFilterCount,
    isLoading,
    content,
    totalCars,
    showNudgeGlow,
    setNudgeGlowConnect
}) => {
    const timerIdRef = useRef(null);
    const queryParams = parseLocationSearchParams(location.search);
    const searchQuery = queryParams?.search;
    const isRecentlyViewed = queryParams?.isRecentlyViewed;
    const isCustomRecommended = queryParams?.isCustomRecommended;
    const [loading, setLoading] = useState(false);

    const onFilterClick = async (selectedFilter) => {
        setLoading(true);
        onFilterSelection({ selectedFilter, selectedOnScreenFilter });
        window.setTimeout(() => {
            setLoading(false);
        }, NUMBER.THOUSAND);
        await yieldToMainThread();
        if (selectedOnScreenFilter === selectedFilter) {
            updateSelectedOnScreenFilterConnect("");
        } else {
            updateSelectedOnScreenFilterConnect(selectedFilter);
        }
        await yieldToMainThread(NUMBER.FIVE_HUNDRED);
        if (timerIdRef.current && window !== undefined) {
            window.clearTimeout(timerIdRef.current);
            setNudgeGlowConnect(false);
        }
        saveCookie(COOKIE_CONSTANTS.LISTING_FILTER_NUDGE_SHOWN, true, NUMBER.SEVEN);
        trackPlatformCustomEventsAEV2(b2cListingPageEvents.filterBucket, {
            eventLabel: "Filter"
        });
    };

    const onLoginSuccess = () => {
        showWishlistPopupConnect(true);
    };

    const handleCompareCheckboxClick = async (value) => {
        await yieldToMainThread();
        setComparingActiveConnect(value);
        await yieldToMainThread();
        if (value) {
            trackPlatformCustomEventsAEV2(b2cListingPageEvents.compareCtaClick, { eventLabel: "listing" });
        } else {
            trackPlatformCustomEventsAEV2(b2cAllPageEvents.b2cDeactivateCompareListing, {
                eventLabel: filteredListingCount
            });
        }
    };

    useEffect(() => {
        if (showNudgeGlow && window !== undefined) {
            trackPlatformCustomEventsAEV2(b2cAllPageEvents.buyUsedCarsFilterNudgeShown);
            timerIdRef.current = window.setTimeout(() => {
                setNudgeGlowConnect(false);
            }, NUMBER.TEN_THOUSAND);
        }

        return () => {
            if (timerIdRef.current && window !== undefined) {
                trackPlatformCustomEventsAEV2(b2cAllPageEvents.buyUsedCarsFilterNudgeShown);
                window.clearTimeout(timerIdRef.current);
                setNudgeGlowConnect(false);
            }
        };
    }, [setNudgeGlowConnect, showNudgeGlow]);

    const getSortLabel = () => {
        if (isRecentlyViewed) {
            return "Recently Viewed";
        } else if (isCustomRecommended) {
            return "Recommended";
        } else if (selectedSort?.key === "releavance") {
            return DEFAULT_SORT_KEY;
        } else {
            return selectedSort?.groupName || selectedSort?.displayText;
        }
    };

    const { onTouchEnd, onTouchStart, onTouchMove } = useSwipeDetect(() => saveCookie(COOKIE_CONSTANTS.LISTING_FILTER_NUDGE_SHOWN, true, NUMBER.SEVEN), NUMBER.TEN);

    return (
        <Fragment>
            <Login
                isVisible={showLogin}
                onClose={() => setShowLogin(false)}
                onSuccess={onLoginSuccess}
            >
                <div  styleName={`styles.filterWrap ${((!(appliedFilters.length > NUMBER.ZERO || searchQuery || selectedSort.key !== DEFAULT_SORT)) || isTabNavTriggered) ? "" : "styles.filterButtonWrapHidden"}`}>
                    {(isLoading && (content || []).length === NUMBER.ZERO) ? <Skeleton /> : (
                        <ul styleName={"styles.filterButtonWrap"}
                            onTouchStart={onTouchStart}
                            onTouchMove={onTouchMove}
                            onTouchEnd={onTouchEnd}>
                            {(isComparingActive || appliedFilters.length > NUMBER.ZERO || searchQuery) && (
                                <CompareCheckboxButton
                                    totalSelectedCarsToCompare={totalSelectedCarsToCompare}
                                    isComparingActive={isComparingActive}
                                    onClick={handleCompareCheckboxClick}
                                />
                            )}
                            <li

                                onClick={onFilterClick.bind(null, FILTER_ENTITIES.MAKE)}
                                styleName={`${selectedFilterCount > NUMBER.ZERO ? "styles.selected" : "styles.filterChip"} ${(showNudgeGlow) ? "styles.animationEffect" : ""}`}
                            >
                                {selectedFilterCount > NUMBER.ZERO ? (
                                    <span styleName={"styles.filtersCount"}>{selectedFilterCount}</span>
                                ) : (
                                    <span styleName={"styles.iconLeft"}  dangerouslySetInnerHTML={{__html: FilterIcon}} />
                                )} Filters
                            </li>
                            {totalCars !== NUMBER.ZERO && (
                                <li styleName={`${getSortLabel() !== DEFAULT_SORT_KEY ? "styles.selected" : "styles.filterChip"}`}>
                                    <SortListingHeader ctaText={getSortLabel()} />
                                </li>
                            )}
                            <FilterSelection />
                            {(showNudgeGlow) ? (
                                <div styleName={"styles.nudgeGrid"}>Try a search or filter!</div>
                            ) : null}
                        </ul>
                    )}
                </div>
                {loading && <PageLoader />}
            </Login>
        </Fragment>
    );
};

CompareCheckboxButton.propTypes = {
    onClick: PropTypes.func,
    isComparingActive: PropTypes.bool,
    totalSelectedCarsToCompare: PropTypes.number
};

FilterDropdown.propTypes = {
    onScreenFilters: PropTypes.array,
    selectedOnScreenFilter: PropTypes.string,
    updateSelectedOnScreenFilterConnect: PropTypes.func,
    onFilterSelection: PropTypes.func,
    isTabNavTriggered: PropTypes.bool,
    showCallButton: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    showWishlistPopupConnect: PropTypes.func,
    setShowLogin: PropTypes.func,
    showLogin: PropTypes.bool,
    appliedFilters: PropTypes.array,
    isComparingActive: PropTypes.bool,
    setComparingActiveConnect: PropTypes.func,
    totalSelectedCarsToCompare: PropTypes.number,
    filteredListingCount: PropTypes.number,
    location: PropTypes.object,
    selectedFilterCount: PropTypes.number,
    isLoading: PropTypes.bool,
    selectedSort: PropTypes.object,
    content: PropTypes.array,
    totalCars: PropTypes.number,
    showNudgeGlow: PropTypes.bool,
    setNudgeGlowConnect: PropTypes.func
};

export default withWishlist(withLogin(FilterDropdown));

/* eslint-disable no-unused-expressions */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import loadable from "@loadable/component";
import InfiniteScroll from "react-infinite-scroller";
import qs from "querystring";

// Styles
import styles from "./styles.css";

// Components
import SmallerInventoryCarCard from "../smaller-inventory-car-card";
import SmallerInventoryCarCardSkeleton from "../smaller-inventory-car-card/skeleton";
import withWishlist from "../with-wishlist";
import withLogin from "../with-login";
import TrackableCarCardItem from "../trackable-car-card-item";
import SeasonalSaleListingBanner from "../seasonal-sale-listing-banner";
import LazyComponent from "../../shared/lazy-component";
const ScrollButton = loadable(() => import("../../shared/scroll-button"));
const NoApiResult = loadable(() => import("../no-api-result"));
const RecommendedCarListV2 = loadable(() => import("../car-list-recommended-v2"));
const SearchNotFoundCarList = loadable(() => import("../search-not-found-car-list"));
const SimilarCarModal = loadable(() => import("../similar-car-modal"));
const SimilarCarModalListing = loadable(() => import("../similar-car-modal-listing"));
const KnowMoreAboutCars = loadable(() => import("../know-more-about-cars"));
const AiCarFinderBanner = loadable(() => import("../ai-car-finder-banner"), {ssr: false});
const CarLoanCalculatorBanner = loadable(() => import("../car-loan-calculator-banner"));

// Constants
import { SOURCE } from "../inventory-car-card/constant";
import { DEFAULT_SORT, EXACT_SEARCH_SORT, SHOW_AD_INDEXES, GA_EVENT_MAP, getInterLinkingFilters, LISTING_SECTION_NAME, PRELOAD_INDEX, INTER_LINKING_FILTERS, SEARCH_SORT, VALUE_PROPOSITION_CARDS } from "./constants";
import { AE_LISTING_API_COUNT, NUMBER, SORT_DATA, COOKIE_CONSTANTS, LISTING_TYPE, CAR_STATUS, LISTING_CARD_CLICK_SECTION, DEFAULT_AE_CITY_NAME } from "../../../constants/app-constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import { defaultParams, defaultFilterPayload } from "../car-listing/constants";
import { appUrl } from "../../../constants/url-constants";
import { SIMILAR_CAR_MODAL_INITIAL } from "../similar-car-modal/constants";
import { BANNER_KEY } from "../value-proposition-card-v2/constant";

// Utilities
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import Filters from "../../../utils/filters-v2";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import debounce from "../../../utils/helpers/debounce";
import saveCookie from "../../../utils/helpers/save-cookie";
import parseCookie from "../../../utils/helpers/parse-cookie";
import updateRecentlyViewedCookie from "../../../utils/helpers/update-recently-viewed-cookie";
import { addToFilterResumeSession, getFilterUrlForResumeSession } from "../../../utils/helpers/filter-resume-session";
import { addToFilterResumeSessionIds, getCustomRecommendedCarsPayload } from "../../../utils/helpers/custom-recommened-car";
import { getAppliedFilterTagsName } from "../../../utils/helpers/applied-filter-tags";
import { addToPrevAppliedFilters } from "../../../utils/helpers/prev-applied-filters";
import { checkCarStatus, getCarName } from "../../../utils/helpers/car-properties.ae";
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import { clearItem, setItem } from "../../../utils/helpers/storage-crud";
import { insertBannerIntoList } from "../../../utils/helpers/insert-banner-into-list";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import carListingEventDataParser, { parseFilterToDimension } from "../../../tracking/parser/car-listing";
import { carListingEventDataParserGA4, parseFilterToDimensionGA4 } from "../../../tracking/parser/car-listing-ga4";
import cleverTap from "../../../tracking/clevertap";
import { searchResultTrackingDataParser } from "../../../tracking/parser/search-result";
import { b2cAllPageEvents } from "../../../tracking/msite/b2c-all-page-events";
import { uncategorizedEvents } from "../../../tracking/msite/uncategorized-events";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";
import { b2cHomePageEvents } from "../../../tracking/msite/b2c-home-page-events";

// Hooks
import useScrollDirection from "../../../hooks/scroll-direction";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
import deleteCookie from "../../../utils/helpers/delete-cookie";

const CarList = ({
    mobile, content, totalCars,
    page, isLoading, fetchCarListConnect,
    config, allFilters, selectedSort, recentlyViewedCars = [],
    location, isSSR, updateSSRStatusConnect,
    selectedCityCode, selectedFilters, urlOptions,
    isLoggedIn, secureToken, fetchWishlistedCarsListConnect,
    resetCarListConnect, shouldReloadList, reloadCarListConnect,
    impressionSource, searchResultTrackingData = {},
    setBackToTopConnect, showBackToTop, appliedFilters,
    fetchSimilarCarListOnSearchConnect,
    updateSelectedSortConnect, fetchRecentlyViewedCarsConnect, route,
    setRecentlyViewedTotal, activeSaleConfig,
    isComparingActive, similarCars, isSimilarCarsFetching, fetchSimilarCarsConnect, selectedCityName, showNudgeGlow,
    getMyBookingsConnect, setNudgeGlowConnect,
    fetchUserDetailsConnect, setTradeInChoiceConnect, customRecommendedCars = [], isCustomRecommendedCarsLoading,
    wishlistedCarsList, showMultiHubBanner, fetchCustomRecommendedCarsConnect, updateAppliedFiltersConnect,
    similarCarPage, similarCarContent, isSimilarCarLoading, totalSimilarCarPages, toggleEmi,
    updateLoanbudgetNudgeConnect, showLoanBugetNudge, carAvailable, carDetailsContent, toggleSnackBarConnect
}) => {
    const timerId = useRef(null);
    const currentCarRef = useRef(null);
    const [similarCarModalData, setSimilarCarModalData] = useState(SIMILAR_CAR_MODAL_INITIAL);
    const { state: { source = ROUTE_NAME.LISTING } = {}, search, pathname } = useLocation();
    const queryParams = parseLocationSearchParams(search);
    const queryPageNumber = queryParams.page ? parseInt(queryParams.page) - 1 : 0;
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [hasMoreSimilarItems, setHasMoreSimilarItems] = useState(true);
    const [showViewLatestCars, setShowViewLatestCars] = useState(queryPageNumber > 0);
    const [latestFilterUrl, setLatestFilterUrl] = useState("");
    const [showErrorScreen, setShowErrorScreen] = useState(false);
    const [isSaleDiscountApplied, setIsSaleDiscountApplied] = useState(false);

    const { active: seasonalSaleActive = false, saleDetails = {}, loginMandatory: isloginMandatoryForSale} = activeSaleConfig || {};
    const { listingPageBannerInfo = {}} = saleDetails;
    const {listingBannerPosition = NUMBER.FOUR} = listingPageBannerInfo || {};

    const { appointmentId: carDetailAppointmentId } = carDetailsContent;

    useEffect(() => {
        if (carDetailAppointmentId && currentCarRef.current) {
            if (window !== undefined) {
                window.setTimeout(() => window.scrollTo(0, currentCarRef.current.offsetTop));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carDetailAppointmentId]);

    const { customPush, customReplace } = useCustomHistoryOperations();

    const handleHidingBackToTop = (scrollDirectionUp) => {
        if (showBackToTop && typeof window !== "undefined") {
            if (scrollDirectionUp && timerId.current) {
                window.clearTimeout(timerId.current);
            }
            timerId.current = window.setTimeout(setBackToTopConnect, NUMBER.SIX_THOUSAND, { show: false, top: NUMBER.EIGHTY });
        } else if (typeof window !== "undefined") {
            window.clearTimeout(timerId.current);
        }
    };

    const isTabNavTriggered = useScrollDirection({ threshold: NUMBER.TEN, callback: debounce(handleHidingBackToTop, NUMBER.FIVE_HUNDRED) });

    const [viewLatestRef, inView, entry] = useInView({ threshold: NUMBER.ZERO });
    const [sixthCardRef, sixthCardInView, sixthCardEntry] = useInView({ threshold: NUMBER.ZERO });

    let listingPath = pathname.replace("/", "");
    if (listingPath.slice(-1) === "/") {
        listingPath = listingPath.substring(0, listingPath.length - 1);
    }
    const queryString = qs.stringify(queryParams);
    const searchQuery = queryParams.search;
    const searchFilterKey = queryParams?.searchFilter || "";
    const isFilterResumeSession = queryParams?.isFilterResumeSession || false;
    const wishlistSimilarAppID = queryParams?.wsAppId || "";
    const similarCarBookingType = queryParams?.scBookingType || "";
    const wishlistSimilarCarName = queryParams?.wsCarName || "";
    const isCustomRecommended = queryParams?.isCustomRecommended || "";
    const totalPages = Math.ceil(totalCars / NUMBER.TWENTY_FIVE);

    const isSortSelected = urlOptions && urlOptions.sort && urlOptions.sort !== DEFAULT_SORT;
    // handling of recently viewed cars in listing
    const [isRecentlyViewedLoading, setIsRecentlyViewedLoading] = useState(queryParams?.isRecentlyViewed);
    const isRecentlyViewed = queryParams?.isRecentlyViewed;
    const recentlyViewedCarsData = JSON.parse(parseCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS) || "[]");
    const apptIdsBiButNoBc = JSON.parse(parseCookie(COOKIE_CONSTANTS.APPT_IDS_BI_BUT_NO_BC) || "[]");
    const apptIdsSpButNoBi = JSON.parse(parseCookie(COOKIE_CONSTANTS.APPT_IDS_SP_BUT_NO_BI) || "[]");
    const apptIdsFilterResumeSession = JSON.parse(parseCookie(COOKIE_CONSTANTS.APPT_IDS_FILTER_RESUME_SESSION) || "[]");

    const recentlyViewedCarsLength = recentlyViewedCarsData?.length || NUMBER.ZERO;
    const filterDimensions = parseFilterToDimension(allFilters, selectedFilters);
    const filterDimensionsGA4 = parseFilterToDimensionGA4(allFilters, appliedFilters, selectedSort);

    const isSeoPath = !Object.keys(queryParams)?.length;

    const handleInitialChanges = async () => {
        // await yieldToMainThread();
        if (appliedFilters.length > NUMBER.ZERO) {
            saveCookie(COOKIE_CONSTANTS.LISTING_FILTER_NUDGE_SHOWN, true, NUMBER.SEVEN);
        }
    };

    useEffect(() => {
        handleInitialChanges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (secureToken) {
            getMyBookingsConnect(
                secureToken,
                { page: NUMBER.ZERO, size: NUMBER.TWENTY, status: "BOOKED,SOLD" },
                { isRefresh: true, isSnackCard: true }
            );
            fetchUserDetailsConnect(secureToken).then((response) => {
                if (response) {
                    setTradeInChoiceConnect(response?.isTradeIn);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureToken]);

    const fetchRecentlyUsedCars = async () => {
        // await yieldToMainThread();
        const filterPayload = {
            ...defaultFilterPayload,
            options: { sf: `appointmentId:${recentlyViewedCarsData?.join(";")}` }
        };
        const params = {
            ...defaultParams,
            variant: "filterV5",
            size: recentlyViewedCarsLength
        };
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { payload } = filters.getListingPayload({ ...filterPayload });
        fetchRecentlyViewedCarsConnect(params, payload).then(({ total, results }) => {
            if (total < recentlyViewedCarsLength) {
                updateRecentlyViewedCookie(results);
            }
            setRecentlyViewedTotal(total);
            setIsRecentlyViewedLoading(false);
        }).catch(() => {
            setShowErrorScreen(true);
            setIsRecentlyViewedLoading(false);
            deleteCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS);
        });
    };

    useEffect(() => {
        if (isRecentlyViewed) {
            fetchRecentlyUsedCars();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecentlyViewed]);

    useEffect(() => {
        if (latestFilterUrl?.length) {
            const newAppliedFilters = [];
            (appliedFilters || []).forEach((appliedFilter) => {
                if (appliedFilter?.isSubFacetFullySelected) {
                    if (appliedFilter?.key === FILTER_ENTITIES.MAKE && appliedFilter?.filterKey === FILTER_ENTITIES.MAKE) {
                        newAppliedFilters.push(appliedFilter?.optionKey);
                    }
                } else {
                    newAppliedFilters.push(getAppliedFilterTagsName(appliedFilter, allFilters));
                }
            });
            addToFilterResumeSession(newAppliedFilters, searchQuery, latestFilterUrl);
            setLatestFilterUrl("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedFilters, searchQuery, latestFilterUrl]);

    const triggerSearchResultTrackingEvent = (carListResponse = {}, pageNumber = NUMBER.ZERO) => {
        const otherProperties = {
            sort: selectedSort?.groupName ?
                `${selectedSort?.groupName} - ${selectedSort?.displayText}` :
                selectedSort?.displayText,
            pageNo: pageNumber,
            ...searchResultTrackingData
        };
        const eventData = searchResultTrackingDataParser(carListResponse, appliedFilters, allFilters, otherProperties);
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.searchResultTracking, eventData);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchListing = (nextPage) => {
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { payload, relativeURL } = filters.getListingPayload({
            selectedFilters,
            filters: allFilters,
            options: {
                ...urlOptions,
                ...isSeoPath && { isSeoFilter: true },
                ...((queryParams?.sort && queryParams.sort !== DEFAULT_SORT) && {
                    sort: queryParams.sort
                }),
                ...(toggleEmi && !carAvailable && {
                    sort: "price-asc"
                }),
                ...(searchQuery && {
                    search: searchQuery,
                    matchType: "EXACT",
                    ...(searchFilterKey && { searchFilter: searchFilterKey }),
                    ...(!isSortSelected && {
                        sort: EXACT_SEARCH_SORT
                    })
                })
            }
        });

        const listingQueryString = isFilterResumeSession ? queryString : payload;
        const pageNo = nextPage || queryPageNumber;

        fetchCarListConnect({
            size: AE_LISTING_API_COUNT,
            spath: listingPath,
            variant: "filterV5",
            page: pageNo
        },
            listingQueryString,
             "v3").then(async (res) => {
                const filterUrl = (!queryString?.length ? location?.pathname : relativeURL) || "";
                if (searchQuery) {
                    trackPlatformCustomEventsAEV2(GA_EVENT_MAP[source], {
                        eventLabel: searchQuery,
                        numberofcars: res.total
                    });
                }
                if (res?.total >= NUMBER.FIVE && !isFilterResumeSession) {
                    await yieldToMainThread();
                    setLatestFilterUrl(filterUrl);
                    addToFilterResumeSessionIds(res?.results);
                }
                if (res?.selectedSort && queryParams?.sort === SORT_DATA.RECENTLY_ADDED.key) {
                    updateSelectedSortConnect({
                        key: res?.selectedSort,
                        displayText: SORT_DATA.RECENTLY_ADDED.displayText
                    });
                }
                // await yieldToMainThread();
                if (selectedFilters?.length) {
                    addToPrevAppliedFilters(filterUrl);
                } else {
                    addToPrevAppliedFilters("");
                }
                if (searchQuery) {
                    setItem(searchQuery, "searchQuery");
                } else {
                    clearItem("searchQuery");
                }
                setHasMoreItems(true);
                await yieldToMainThread();
                triggerSearchResultTrackingEvent(res, pageNo);
                cleverTap.mobileCleverTapEvent(cleverTap.mobileConstants.RESUME_SESSION_DEEPLINK, {
                    filterUrl: `${appUrl()}${getFilterUrlForResumeSession(filterUrl)}`
                });
            }).catch(() => {
                setShowErrorScreen(true);
            });
    };

    const shouldShowViewLatestCars = (index) => {
        if (index !== NUMBER.ONE ||
            content.length === NUMBER.ZERO ||
            page < NUMBER.ONE
        ) { return false; }
        return showViewLatestCars;
    };

    const sortType = () => {
        if (toggleEmi && totalCars < NUMBER.TEN && totalCars > NUMBER.ZERO) {
            return "price-asc";
        } else if (urlOptions.sort) {
            return urlOptions.sort;
        } else if ((searchQuery) || appliedFilters.length > NUMBER.ZERO) {
            return EXACT_SEARCH_SORT;
        }
        return SEARCH_SORT;
    };

    const fetchSimilarCars = (similarCarListPage = NUMBER.ZERO) => {
        if (isSimilarCarLoading) return;
        setHasMoreSimilarItems(false);
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { payload } = filters.getListingPayload({
            selectedFilters,
            filters: allFilters,
            options: {
                ...({
                    ...urlOptions,
                    search: searchQuery,
                    matchType: "SIMILAR",
                    // eslint-disable-next-line no-nested-ternary
                    sort: sortType()
                })
            }
        });

        fetchSimilarCarListOnSearchConnect({
            size: AE_LISTING_API_COUNT,
            spath: listingPath,
            variant: "filterV5",
            page: similarCarListPage
        },
            payload,
            "v3").then(() => {
                setHasMoreSimilarItems(true);
            });
    };

    const loadSimilarCars = () => {
        setHasMoreSimilarItems(false);
        if ((similarCarPage + 1 < totalSimilarCarPages) && !isSimilarCarLoading || totalSimilarCarPages === null && !isSimilarCarLoading) {
            fetchSimilarCars(similarCarPage + 1);
        }
    };

    const loadMore = () => {
        setHasMoreItems(false);
        if ((page + 1 < totalPages) && !isLoading || totalPages === null && !isLoading) {
            fetchListing(page + 1);
        } else if ((isSeoPath && totalCars < NUMBER.TEN) || (!isSeoPath && (searchQuery || appliedFilters.length > NUMBER.ZERO))) {
            loadSimilarCars();
        }
    };

    const onClickViewLatestCars = () => {
        window.scrollTo(0, 0);
        setHasMoreItems(true);
        updateSSRStatusConnect(false);
        setShowViewLatestCars(false);
    };

    const eventData = (index, carObject) => {
        const data = carListingEventDataParser(carObject, {
            position: index,
            list: impressionSource,
            filterDimensions
        });
        return {
            name: "carsImpression",
            data: {
                ...data.carsImpression,
                eventLabel: LISTING_TYPE.CAR_LISTING_PAGE
            }
        };
    };

    const triggerGA4ViewItemListEvent = (index = 0, carObject = {}) => {
        const dataGA4 = carListingEventDataParserGA4(carObject, {
            position: index + 1,
            filterDimensions: filterDimensionsGA4,
            listingType: LISTING_TYPE.CAR_LISTING_PAGE,
            locationId: selectedCityCode
        });
        return {
            name: "ga4ViewItemList",
            data: {
                user_id: mobile,
                ...dataGA4,
                eventLabel: LISTING_TYPE.CAR_LISTING_PAGE
            }
        };
    };

    const triggerGA4SelectContentEvent = (index, car = {}) => {
        const dataGA4 = carListingEventDataParserGA4(car, {
            position: index + 1,
            filterDimensions: filterDimensionsGA4,
            listingType: LISTING_TYPE.CAR_LISTING_PAGE,
            locationId: selectedCityCode
        });
        trackPlatformCustomEventsAEV2(uncategorizedEvents.clearEccomerce);
        trackPlatformCustomEventsAEV2(uncategorizedEvents.ga4SelectContent, {
            user_id: mobile,
            ...dataGA4
        });
    };

    const triggerCarStatusGAEvent = (eventName = "", car) => {
        if (checkCarStatus(car, CAR_STATUS.RESERVED)) {
            trackPlatformCustomEventsAEV2(eventName, { eventLabel: "reserved" });
        } else if (checkCarStatus(car, CAR_STATUS.SOLD)) {
            trackPlatformCustomEventsAEV2(eventName, { eventLabel: "sold" });
        } else {
            trackPlatformCustomEventsAEV2(eventName, { eventLabel: "available" });
        }
    };

    const triggerGAEvent = ({ sectionName, carDetails }) => {
        const isSearchApplied = !!searchQuery;
        const isFilterApplied = !!appliedFilters.length > NUMBER.ZERO;

        let eventLabel = "NA";
        if (sectionName === LISTING_SECTION_NAME.RECOMMENDED_CARS_SECTION) {
            if (isSearchApplied && !isFilterApplied) {
                eventLabel = LISTING_CARD_CLICK_SECTION?.SEARCH_EXACT;
            } else if (!isSearchApplied && isFilterApplied) {
                eventLabel = LISTING_CARD_CLICK_SECTION?.FILTER_EXACT;
            } else if (isSearchApplied && isFilterApplied) {
                eventLabel = LISTING_CARD_CLICK_SECTION?.SEARCH_FILTER_EXACT;
            } else if (queryParams?.sort === SORT_DATA.RECENTLY_ADDED.key) {
                eventLabel = LISTING_CARD_CLICK_SECTION?.NEWLY_ADDED;
            } else {
                eventLabel = LISTING_CARD_CLICK_SECTION?.NORMAL_LISTING;
            }
        } else if (sectionName === LISTING_SECTION_NAME.SEARCH_SIMILAR_CARS_SECTION) {
            if (isSearchApplied && !isFilterApplied) {
                eventLabel = LISTING_CARD_CLICK_SECTION?.SEARCH_SIMILAR;
            } else if (!isSearchApplied && isFilterApplied) {
                eventLabel = LISTING_CARD_CLICK_SECTION?.FILTER_SIMILAR;
            } else if (isSearchApplied && isFilterApplied) {
                eventLabel = LISTING_CARD_CLICK_SECTION?.SEARCH_FILTER_SIMILAR;
            }
        } else if (sectionName === LISTING_SECTION_NAME.RECOMMENDED_CARS_SECTION) {
            eventLabel = LISTING_CARD_CLICK_SECTION?.BOTTOM_RECOMMENDED;
        } else if (sectionName === LISTING_SECTION_NAME.CUSTOM_RECOMMENDED_CARS_SECTION) {
            eventLabel = LISTING_CARD_CLICK_SECTION?.RECOMMENDED_REPEAT;
        } else if (sectionName === LISTING_SECTION_NAME.RECENTLY_VIEWED_CARS_SECTION) {
            eventLabel = LISTING_CARD_CLICK_SECTION?.RECENTLY_VIEWED;
        }

        trackPlatformCustomEventsAEV2(b2cListingPageEvents.listingCarCardClick, { eventLabel, appointment_id: carDetails?.appointmentId || "NA" });
    };

    const onClickCallback = async (index, car = {}, listingSectionName = "") => {
        const data = carListingEventDataParser(car, {
            position: index,
            list: impressionSource,
            filterDimensions
        });
        triggerGAEvent({ listingSectionName, carDetails: car });
        trackPlatformCustomEventsAEV2(uncategorizedEvents.carClick, data.carClick);
        const isCarReserved = ((car?.booked && car?.listingActive) || (!car?.booked && !car?.listingActive));
        if (isCarReserved) {
            trackPlatformCustomEventsAEV2(b2cListingPageEvents.reservedcarCardClick, {
                eventLabel: car?.appointmentId || "NA"
            });
        } else {
            trackPlatformCustomEventsAEV2(b2cListingPageEvents.carDetailsClickListingPage, {
                eventLabel: car?.appointmentId || "NA"
            });
        }
        triggerGA4SelectContentEvent(index, car);
        if (listingSectionName === LISTING_SECTION_NAME.WISHLISTED_SIMILAR_CARS_SECTION) {
            triggerCarStatusGAEvent(b2cListingPageEvents.wishlistSimilarCarListingCarClick, car);
        }
    };
    const navigateToListing = async () => {
        await yieldToMainThread();
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { relativeURL } = filters.getListingPayload({ selectedFilters, filters: allFilters, options: {} });
        updateAppliedFiltersConnect();
        reloadCarListConnect(true);
        setIsSaleDiscountApplied(false);
        await customReplace(relativeURL);
        scrollToTop();
    };

    const onLoanEligiliblityClick = () => {

    };
    useEffect(() => {
        const selectedFiltersKey = selectedFilters.map((filterType) => filterType.filterKey);
        const isDiscountFilterApplied = selectedFiltersKey.includes(FILTER_ENTITIES.DISCOUNT);
        if (isSaleDiscountApplied && isDiscountFilterApplied) {
            navigateToListing();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters, isSaleDiscountApplied]);

    const renderAdditionalListComponents = (itemIndex) => {
        const propositionCard = VALUE_PROPOSITION_CARDS;
        let propositionCardUpdated = { ...propositionCard };

        const AiCarFinderBannerList = {
            list: [
                {
                    Component: AiCarFinderBanner,
                    props: { source: "listing_banner" }
                }
            ]
        };

        propositionCardUpdated = insertBannerIntoList(propositionCardUpdated, NUMBER.NINE, AiCarFinderBannerList);

        const loanCalcBanner = {
            list: [
                {
                    Component: CarLoanCalculatorBanner,
                    props: { source: "listing_banner", onLoginSuccess: onLoanEligiliblityClick }
                }
            ]
        };
        propositionCardUpdated = insertBannerIntoList(propositionCardUpdated, NUMBER.NINE, loanCalcBanner);

        if (seasonalSaleActive) {
            const seasonalSaleBanner = {
                list: [
                    {
                        Component: SeasonalSaleListingBanner,
                        props: {
                            seasonalSale: listingPageBannerInfo,
                            isloginMandatoryForSale
                        }
                    }
                ]
            };
            propositionCardUpdated = insertBannerIntoList(propositionCardUpdated, listingBannerPosition, seasonalSaleBanner);
        }

        const knowMoreAboutCarsSection = {
            list: [
                {
                    Component: KnowMoreAboutCars,
                    props: {}
                }
            ]
        };

        propositionCardUpdated = insertBannerIntoList(propositionCardUpdated, NUMBER.FOUR, knowMoreAboutCarsSection);

        const test = { ...propositionCardUpdated, ...getInterLinkingFilters(appliedFilters, isSeoPath, selectedCityName) };
        const list = test[itemIndex]?.list || [];
        return list.map(({ Component, props }) => {
            // eslint-disable-next-line react/prop-types
            if (!showMultiHubBanner && props?.bannerKey === BANNER_KEY.YARD_BANNER_SHARJAH) {
                return "";
            }
            if (itemIndex <= NUMBER.ONE) {
                return <Component key={`additionalComponent_${itemIndex}`} {...props} itemIndex={itemIndex} />;
            } else {
                return <LazyComponent key={`additionalComponent_${itemIndex}`}><Component {...props} itemIndex={itemIndex} /></LazyComponent>;
            }

        });
    };

    useEffect(() => {
        updateSSRStatusConnect(false);
    }, [updateSSRStatusConnect]);

    useEffect(() => {

        if (!isSSR && shouldReloadList) {
            resetCarListConnect();
            fetchListing();
            reloadCarListConnect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryString, isSSR, shouldReloadList, isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn && secureToken) {
            fetchWishlistedCarsListConnect(secureToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {
        handleHidingBackToTop(isTabNavTriggered);
        return () => {
            if (timerId.current && typeof window !== "undefined") {
                window.clearTimeout(timerId.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBackToTop, isTabNavTriggered]);

    useEffect(() => {
        if (isTabNavTriggered && (sixthCardInView || (sixthCardEntry?.boundingClientRect?.y || 1) < 0)) {
            // eslint-disable-next-line no-extra-boolean-cast
            setBackToTopConnect({
                show: true,
                top: (appliedFilters?.length > NUMBER.ZERO) ? NUMBER.ONE_HUNDRED_EIGHTY_EIGHT : NUMBER.ONE_HUNDRED_THIRTY_FOUR
            });
            trackPlatformCustomEventsAEV2(b2cListingPageEvents.b2cBackToTopShownListingPage);
        } else {
            setBackToTopConnect({ show: false, top: NUMBER.EIGHTY });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sixthCardInView, isTabNavTriggered]);

    // To update similarCar list to empty on searchQuery Change
    useEffect(() => {
        if (totalCars < NUMBER.TWO && !isLoading && (similarCarContent || []).length === 0 && !isSimilarCarLoading) {
            loadMore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, isLoading, totalCars]);

    const handleListingNudgeExperiment = () => {

        const isListingFilterNudgeSeen = parseCookie(COOKIE_CONSTANTS.LISTING_FILTER_NUDGE_SHOWN) === "true";

        if (!showNudgeGlow && !isListingFilterNudgeSeen) {
            setNudgeGlowConnect(true);
            window.setTimeout(() => {
                setNudgeGlowConnect(false);
            }, NUMBER.THREE_THOUSAND);
            saveCookie(COOKIE_CONSTANTS.LISTING_FILTER_NUDGE_SHOWN, true, NUMBER.SEVEN);
            trackPlatformCustomEventsAEV2(b2cHomePageEvents.listingFilterNudge);
        }
    };

    const handleLoanNudgeExperiment = () => {
        const isListingLoanNudgeSeen = parseCookie(COOKIE_CONSTANTS.LOAN_NUDGE_SHOWN) === "true";

        if (!showLoanBugetNudge && !isListingLoanNudgeSeen) {
            updateLoanbudgetNudgeConnect(true);
            window.setTimeout(() => {
                updateLoanbudgetNudgeConnect(false);
            }, NUMBER.THREE_THOUSAND);
            saveCookie(COOKIE_CONSTANTS.LOAN_NUDGE_SHOWN, true);
            trackPlatformCustomEventsAEV2(b2cListingPageEvents.carAffordabilityLoanNudgeShown);
        }
    };
    const handleInViewCar = (index) => {
        const isListingFilterNudgeSeen = parseCookie(COOKIE_CONSTANTS.LISTING_FILTER_NUDGE_SHOWN) === "true";
        const isAiPopupShown = parseCookie(COOKIE_CONSTANTS.HIDE_AI_BOT_POPUP) === "true";

        toggleSnackBarConnect(index <= NUMBER.TWO);
        if (index === NUMBER.FOURTEEN && (appliedFilters.length === NUMBER.ZERO) && !searchQuery && selectedSort.key === DEFAULT_SORT) {
            handleListingNudgeExperiment();
        }
        if (index === NUMBER.NINE &&
            (isListingFilterNudgeSeen ||
                isAiPopupShown ||
                appliedFilters.length > NUMBER.ZERO ||
                searchQuery)
        ) {
            handleLoanNudgeExperiment();
        }
    };

    useEffect(() => {
        if (wishlistSimilarAppID) {
            const params = {
                size: AE_LISTING_API_COUNT,
                ...(similarCarBookingType && { bookingType: similarCarBookingType })
            };
            fetchSimilarCarsConnect(wishlistSimilarAppID, params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wishlistSimilarAppID]);

    // CUSTOM RECOMMENDED SECTION
    const fetchCustomRecommendedCars = async () => {
        await yieldToMainThread();
        const customRecommendedApptIds = getCustomRecommendedCarsPayload({
            apptIdsBiButNoBc,
            apptIdsSpButNoBi,
            wishlistedCarsList,
            apptIdsFilterResumeSession,
            apptIdsRecentlyViewed: recentlyViewedCarsData,
            isRemoveRecentlyViewedCars: false,
            apptListSize: AE_LISTING_API_COUNT
        });
        const filterPayload = {
            ...defaultFilterPayload,
            options: { sf: `appointmentId:${customRecommendedApptIds?.join(";")}` }
        };
        const params = {
            ...defaultParams,
            variant: "filterV5",
            size: AE_LISTING_API_COUNT
        };
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { payload } = filters.getListingPayload({ ...filterPayload });
        fetchCustomRecommendedCarsConnect(params, payload)
            // eslint-disable-next-line no-unused-vars
            .catch((error) => { });
    };

    useEffect(() => {
        if (isCustomRecommended) {
            fetchCustomRecommendedCars();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCustomRecommended]);

    const isShowViewSimilarIcon = (item) => {
        return checkCarStatus({ booked: item?.booked, listingActive: item?.listingActive }, CAR_STATUS.RESERVED);
    };

    const handleSimilarCarModalClose = () => {
        setSimilarCarModalData(SIMILAR_CAR_MODAL_INITIAL);
    };

    const redirectToCarDetailPage = async (car = {}) => {
        const { year, make, model, appointmentId, city } = car || {};
        const carName = getCarName({ year, make, model });
        const { relativeURL } = getDetailPageUrl({ year, carName, carId: appointmentId, city, country: "ae" });
        await customPush(relativeURL);
    };

    const redirectToSimilarCarListing = async (carData = {}) => {
        const { year, make, model, appointmentId = "" } = carData || {};
        const carName = getCarName({ year, make, model });
        const queryParameters = appointmentId ? `?wsAppId=${appointmentId}&wsCarName=${carName}` : "";

        reloadCarListConnect(true);
        await customPush(`/buy-used-cars-${dashedLowercase(selectedCityName || DEFAULT_AE_CITY_NAME)}${queryParameters}`);
        window.setTimeout(() => {
            window.scrollTo(NUMBER.ZERO, NUMBER.ZERO);
        });
    };

    // const hideAiCarFinderSemiModal = parseCookie(COOKIE_CONSTANTS.HIDE_AI_BOT_POPUP);
    return (
        <Fragment>
            {(!inView && entry && showViewLatestCars && !isLoading && page > 1) &&
                <ScrollButton text={"View Latest Cars"} onClick={onClickViewLatestCars} />
            }
            {isRecentlyViewed && (
                <section styleName={"styles.recentlyViewedCars"}>
                    <div styleName={"styles.containerWrap"}>
                        {!isRecentlyViewedLoading && (recentlyViewedCars || []).map((item, index) => (
                            <Fragment key={`${index}_${item?.appointmentId}`}>
                                <TrackableCarCardItem
                                    event={eventData(index, item)}
                                    eventDataGA4={triggerGA4ViewItemListEvent(index, item)}
                                    itemIndex={index}
                                >
                                    <SmallerInventoryCarCard
                                        cardRef={shouldShowViewLatestCars(index) ? viewLatestRef : undefined}
                                        currentCarRef={carDetailAppointmentId && carDetailAppointmentId === item?.appointmentId ? currentCarRef : undefined}
                                        key={`${index}_${item?.appointmentId}`}
                                        {...item}
                                        config={config}
                                        source={SOURCE.LISTING}
                                        onClickCallback={() => onClickCallback(index, item, LISTING_SECTION_NAME.RECENTLY_VIEWED_CARS_SECTION)}
                                        routeName={route.name}
                                        preload={PRELOAD_INDEX.includes(index)}
                                        lazyLoadImage={!PRELOAD_INDEX.includes(index)}
                                        isShowViewSimilarIcon={isShowViewSimilarIcon(item)}
                                    />
                                </TrackableCarCardItem>
                                {carDetailAppointmentId && carDetailAppointmentId === item?.appointmentId ? <SimilarCarModalListing /> : null}
                            </Fragment>
                        ))}
                        {isRecentlyViewedLoading && Array(...new Array(recentlyViewedCarsLength)).map((i, index) => {
                            return <SmallerInventoryCarCardSkeleton key={index} />;
                        })}
                    </div>
                </section>
            )}
            {wishlistSimilarAppID && (
                <section styleName={"styles.wishlistSimilarSectionWrapper"}>
                    <div styleName={"styles.containerWrap"}>
                        {similarCars?.length > NUMBER.ZERO && (
                            <h2>Similar cars to {wishlistSimilarCarName?.toUpperCase()}</h2>
                        )}
                        {!isSimilarCarsFetching && (similarCars || [])?.map((item, index) => (
                            <Fragment key={`${index}_${item?.appointmentId}`}>
                                <TrackableCarCardItem
                                    event={eventData(index, item)}
                                    eventDataGA4={triggerGA4ViewItemListEvent(index, item)}
                                    itemIndex={index}
                                >
                                    <SmallerInventoryCarCard
                                        cardRef={shouldShowViewLatestCars(index) ? viewLatestRef : undefined}
                                        currentCarRef={carDetailAppointmentId && carDetailAppointmentId === item?.appointmentId ? currentCarRef : undefined}
                                        key={`${index}_${item?.appointmentId}`}
                                        {...item}
                                        config={config}
                                        source={SOURCE.LISTING}
                                        onClickCallback={() => onClickCallback(index, item, LISTING_SECTION_NAME.WISHLISTED_SIMILAR_CARS_SECTION)}
                                        routeName={route?.name}
                                        preload={PRELOAD_INDEX.includes(index)}
                                        lazyLoadImage={!PRELOAD_INDEX.includes(index)}
                                        isShowViewSimilarIcon={isShowViewSimilarIcon(item)}
                                    />
                                </TrackableCarCardItem>
                                {carDetailAppointmentId && carDetailAppointmentId === item?.appointmentId ? <SimilarCarModalListing /> : null}
                            </Fragment>
                        ))}
                        {isSimilarCarsFetching && (
                            Array(...new Array(AE_LISTING_API_COUNT))?.map((i, index) => <SmallerInventoryCarCardSkeleton key={index} />)
                        )}
                    </div>
                </section>
            )}
            {isCustomRecommended && (
                <section>
                    <div styleName={"styles.containerWrap"}>
                        {!isCustomRecommendedCarsLoading && (customRecommendedCars || [])?.map((item, index) => (
                            <Fragment key={`${index}_${item?.appointmentId}`}>
                                <TrackableCarCardItem
                                    event={eventData(index, item)}
                                    eventDataGA4={triggerGA4ViewItemListEvent(index, item)}
                                    itemIndex={index}
                                >
                                    <SmallerInventoryCarCard
                                        cardRef={shouldShowViewLatestCars(index) ? viewLatestRef : undefined}
                                        currentCarRef={carDetailAppointmentId && carDetailAppointmentId === item?.appointmentId ? currentCarRef : undefined}
                                        key={`${index}_${item?.appointmentId}`}
                                        {...item}
                                        config={config}
                                        source={SOURCE.LISTING}
                                        onClickCallback={() => onClickCallback(index, item, LISTING_SECTION_NAME.CUSTOM_RECOMMENDED_CARS_SECTION)}
                                        routeName={route?.name}
                                        preload={PRELOAD_INDEX.includes(index)}
                                        lazyLoadImage={!PRELOAD_INDEX.includes(index)}
                                        isShowViewSimilarIcon={isShowViewSimilarIcon(item)}
                                    />
                                </TrackableCarCardItem>
                                {carDetailAppointmentId && carDetailAppointmentId === item?.appointmentId ? <SimilarCarModalListing /> : null}
                            </Fragment>
                        ))}
                        {isCustomRecommendedCarsLoading && (
                            Array(...new Array(AE_LISTING_API_COUNT))?.map((i, index) => <SmallerInventoryCarCardSkeleton key={index} />)
                        )}
                    </div>
                </section>
            )}
            <section>
                <div styleName={"styles.containerWrap"}>
                    {((totalCars > NUMBER.ZERO) && (isRecentlyViewed || wishlistSimilarAppID)) && <h2 styleName={"styles.heading styles.mbTwentyFour"}>Recommended Cars</h2>}
                    {(totalCars === 0 && (similarCarContent || []).length === 0 && !isLoading && !isSimilarCarLoading) && (
                        <div className={"mt-4 mb-5"}>
                            <SearchNotFoundCarList />
                        </div>
                    )}
                    <InfiniteScroll
                        pageStart={0}
                        hasMore={hasMoreItems}
                        loadMore={loadMore}
                        initialLoad={false}
                    >
                        {content.map((item, index) => (
                            <Fragment key={index}>
                                <TrackableCarCardItem
                                    event={eventData(index, item)}
                                    eventDataGA4={triggerGA4ViewItemListEvent(index, item)}
                                    itemIndex={index}
                                    onVisibilityChange={handleInViewCar}
                                >
                                    <SmallerInventoryCarCard
                                        cardRef={shouldShowViewLatestCars(index) ? viewLatestRef : undefined}
                                        currentCarRef={carDetailAppointmentId && carDetailAppointmentId === item?.appointmentId ? currentCarRef : undefined}
                                        key={index}
                                        {...item}
                                        config={config}
                                        source={SOURCE.LISTING}
                                        onClickCallback={() => onClickCallback(index, item, LISTING_SECTION_NAME.RECOMMENDED_CARS_SECTION)}
                                        routeName={route.name}
                                        preload={PRELOAD_INDEX.includes(index)}
                                        lazyLoadImage={!PRELOAD_INDEX.includes(index)}
                                        isComparingActive={isComparingActive}
                                        isShowViewSimilarIcon={isShowViewSimilarIcon(item)}
                                        crawable={index < NUMBER.FOURTY}
                                    />
                                </TrackableCarCardItem>
                                {carDetailAppointmentId && carDetailAppointmentId === item?.appointmentId ? <SimilarCarModalListing /> : null}
                                {index === NUMBER.FIVE && <div ref={sixthCardRef} />}
                                {
                                    SHOW_AD_INDEXES.includes(index) &&
                                    renderAdditionalListComponents(index)
                                }
                            </Fragment>
                        )
                        )}
                        {(isLoading) && Array(...new Array(AE_LISTING_API_COUNT)).map((i, index) => {
                            return <SmallerInventoryCarCardSkeleton key={index} />;
                        })}
                    </InfiniteScroll>
                    {content.length > 0 && content.length <= NUMBER.TWENTY_FIVE && isSeoPath && Object.keys(INTER_LINKING_FILTERS).map(item => Number(item) < content.length ? null : renderAdditionalListComponents(item))}
                </div>
                <InfiniteScroll
                    pageStart={0}
                    hasMore={hasMoreSimilarItems}
                    loadMore={loadSimilarCars}
                    initialLoad={false}
                >
                    {(similarCarContent?.length > NUMBER.ZERO) && (
                        <section styleName={"styles.similarSectionWrapper"}>
                            <div styleName={"styles.containerWrap"}>
                                <h2 styleName={"styles.heading"}>{toggleEmi && totalCars > NUMBER.ZERO ? "Cars you can buy with full payment" : "Similar cars as per your search"}</h2>
                                {(similarCarContent).map((item, index) => (
                                    <Fragment key={`${index}_${item?.appointmentId}`}>
                                        <TrackableCarCardItem
                                            event={eventData(index, item)}
                                            eventDataGA4={triggerGA4ViewItemListEvent(index, item)}
                                            itemIndex={index}
                                        >
                                            <SmallerInventoryCarCard
                                                cardRef={shouldShowViewLatestCars(index) ? viewLatestRef : undefined}
                                                currentCarRef={carDetailAppointmentId && carDetailAppointmentId === item?.appointmentId ? currentCarRef : undefined}
                                                key={`${index}_${item?.appointmentId}`}
                                                {...item}
                                                config={config}
                                                source={SOURCE.LISTING}
                                                onClickCallback={() => onClickCallback(index, item, LISTING_SECTION_NAME.SEARCH_SIMILAR_CARS_SECTION)}
                                                routeName={route.name}
                                                preload={PRELOAD_INDEX.includes(index)}
                                                lazyLoadImage={!PRELOAD_INDEX.includes(index)}
                                                isShowViewSimilarIcon={isShowViewSimilarIcon(item)}
                                                crawable={!(isSeoPath && index > NUMBER.FOUR)}
                                            />
                                        </TrackableCarCardItem>
                                        {carDetailAppointmentId && carDetailAppointmentId === item?.appointmentId ? <SimilarCarModalListing /> : null}
                                    </Fragment>
                                ))}
                            </div>
                        </section>
                    )}
                    <div styleName={"styles.containerWrap"}>
                        {(isSimilarCarLoading) && Array(...new Array(AE_LISTING_API_COUNT)).map((i, index) => {
                            return <SmallerInventoryCarCardSkeleton key={index} />;
                        })}
                    </div>
                </InfiniteScroll>
                {searchQuery && !isRecentlyViewedLoading && !isSimilarCarLoading && (
                    <div styleName={"styles.containerWrap styles.recommededSection"}>
                        <RecommendedCarListV2 route={route} />
                    </div>
                )}
            </section>

            {similarCarModalData?.isOpen && (
                <SimilarCarModal
                    config={config}
                    carData={similarCarModalData?.carData}
                    redirectToSimilarCarListing={redirectToSimilarCarListing}
                    redirectToCarDetailPage={redirectToCarDetailPage}
                    handleClose={handleSimilarCarModalClose}
                />
            )}

            {showErrorScreen && (
                <NoApiResult
                    showErrorScreen={showErrorScreen}
                    setShowErrorScreen={setShowErrorScreen}
                />
            )}
        </Fragment>
    );
};

CarList.propTypes = {
    content: PropTypes.array,
    totalCars: PropTypes.number,
    page: PropTypes.number,
    isLoading: PropTypes.bool,
    fetchCarListConnect: PropTypes.func,
    config: PropTypes.object,
    updateSSRStatusConnect: PropTypes.func,
    allFilters: PropTypes.object,
    selectedSort: PropTypes.object,
    location: PropTypes.object,
    isSSR: PropTypes.bool,
    selectedCityCode: PropTypes.string,
    selectedFilters: PropTypes.array,
    recentlyViewedCars: PropTypes.array,
    searchResultTrackingData: PropTypes.object,
    urlOptions: PropTypes.object,
    resetCarListConnect: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    secureToken: PropTypes.string,
    fetchWishlistedCarsListConnect: PropTypes.func,
    shouldReloadList: PropTypes.bool,
    reloadCarListConnect: PropTypes.func,
    impressionSource: PropTypes.string,
    fetchSimilarCarListOnSearchConnect: PropTypes.func,
    setBackToTopConnect: PropTypes.func,
    showBackToTop: PropTypes.bool,
    appliedFilters: PropTypes.array,
    route: PropTypes.object,
    mobile: PropTypes.string,
    updateSelectedSortConnect: PropTypes.func,
    fetchRecentlyViewedCarsConnect: PropTypes.func,
    setRecentlyViewedTotal: PropTypes.func,
    seasonalSale: PropTypes.object,
    isComparingActive: PropTypes.bool,
    similarCars: PropTypes.array,
    isSimilarCarsFetching: PropTypes.bool,
    fetchSimilarCarsConnect: PropTypes.func,
    getMyBookingsConnect: PropTypes.func,
    showNudgeGlow: PropTypes.bool,
    setNudgeGlowConnect: PropTypes.func,
    selectedCityName: PropTypes.string,
    fetchUserDetailsConnect: PropTypes.func,
    setTradeInChoiceConnect: PropTypes.func,
    bottomNavData: PropTypes.string,
    customRecommendedCars: PropTypes.array,
    isCustomRecommendedCarsLoading: PropTypes.bool,
    wishlistedCarsList: PropTypes.array,
    fetchCustomRecommendedCarsConnect: PropTypes.func,
    listingSearchSplitData: PropTypes.string,
    tradeInChoice: PropTypes.bool,
    showMultiHubBanner: PropTypes.bool,
    similarCarPage: PropTypes.number,
    similarCarContent: PropTypes.array,
    isSimilarCarLoading: PropTypes.bool,
    totalSimilarCars: PropTypes.number,
    totalSimilarCarPages: PropTypes.number,
    hasCitySkip: PropTypes.bool,
    updateAppliedFiltersConnect: PropTypes.func,
    toggleEmi: PropTypes.bool,
    updateLoanbudgetNudgeConnect: PropTypes.func,
    showLoanBugetNudge: PropTypes.bool,
    carAvailable: PropTypes.bool,
    carDetailsContent: PropTypes.object,
    activeSaleConfig: PropTypes.object,
    toggleSnackBarConnect: PropTypes.func
};

export default withWishlist(withLogin(CarList));

import React from "react";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import {setNudgeGlow, updateSelectedOnScreenFilter} from "../filters/actions";
import {reloadCarList} from "../car-listing/actions";
import { setComparingActive } from "../compare-widget/actions";

// Component
import FilterDropdown from "./component";

const mapStateToProps = ({
    filters: {
        appliedFilters,
        onScreenFilters,
        selectedOnScreenFilter,
        selectedFilters,
        selectedSuggestions,
        urlOptions,
        selectedSort,
        listingCount: filteredListingCount,
        selectedFilterCount,
        showNudgeGlow
    },
    carListing: {
        isLoading,
        content,
        totalCars
    },
    cities: {
        selectedCity: {
            code: selectedCityCode
        } = {}
    },
    compareCars: {
        isComparingActive,
        totalCars: totalSelectedCarsToCompare
    }
}) => ({
    appliedFilters,
    onScreenFilters,
    selectedOnScreenFilter,
    selectedCityCode,
    selectedFilters,
    selectedSuggestions,
    urlOptions,
    isComparingActive,
    totalSelectedCarsToCompare,
    filteredListingCount,
    selectedFilterCount,
    isLoading,
    content,
    selectedSort,
    showNudgeGlow,
    totalCars
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSelectedOnScreenFilterConnect: updateSelectedOnScreenFilter,
    reloadCarListConnect: reloadCarList,
    setComparingActiveConnect: setComparingActive,
    setNudgeGlowConnect: setNudgeGlow
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterDropdown));

/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { DEFAULT_AE_CITY_NAME, GLOBAL_URL, NUMBER } from "../../../constants/app-constants";
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { b2cDetailPageEvents } from "../../../tracking/msite/b2c-detail-page-events";

import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import { useRouteMatch } from "react-router-dom";

// Utilities
import dashedLowercase from "../../../../client/utils/helpers/dashed-lowercase";
import { getPrevAppliedFilters } from "../../../utils/helpers/prev-applied-filters";
import { getFilterUrlForResumeSession } from "../../../utils/helpers/filter-resume-session";
import { getCarName } from "../../../utils/helpers/car-properties.ae";
import Filters from "../../../utils/filters-v2";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

// eslint-disable-next-line complexity
const BuyCarBreadCrumbV2 = ({
    selectedCity = {},
    content,
    appliedFilters,
    reloadCarListConnect,
    clearAllFiltersConnect
}) => {
    const match = useRouteMatch();
    const { name, code } = selectedCity;
    const { path, url: pathUrl } = match;
    const { customPush } = useCustomHistoryOperations();
    const isGlobalUaeUrl = (pathUrl === "/buy-used-cars-uae/" || pathUrl === "/buy-used-cars-uae");
    const isDetailsPage = path === "/buy-used-:carMakeModel-cars-:city-:lid(\\d+)";
    const selectedFilters = Array.isArray(appliedFilters) && !!appliedFilters.length && appliedFilters[0];
    const { optionKey = "", subOptionKey = "", min = 0, max = 0, filterKey = "NA", isSubFacetFullySelected = true } = selectedFilters;
    const isListingPage = path === "/buy-used-:filterQuery";
    const citySlug = ((name || DEFAULT_AE_CITY_NAME).replace(/ /g, "-")).toLowerCase();
    const { make, model } = content || {};
    const carName = getCarName(content);
    const trackEvent = (eventLabel) => {
        trackPlatformCustomEventsAEV2(b2cDetailPageEvents.breadcrumbsDetailPage, {
            eventLabel
        });
    };

    const onLinkClickHandler = async (e, type = "", { url }) => {
        if (isDetailsPage && (type === "city" || type === "make")) {
            const filterData = getPrevAppliedFilters();
            if (filterData?.latestFilterUrl) {
                e.preventDefault();
                trackEvent(filterData?.latestFilterUrl);
                await customPush(getFilterUrlForResumeSession(filterData?.latestFilterUrl));
            } else {
                trackEvent(url);
            }
        } else if (type === "globalUrl") {
            clearAllFiltersConnect();
            e.preventDefault();
            const filters = new Filters({ cityCode: code, cityName: name });
            const { relativeURL } = filters.getListingPayload({ selectedFilters: [], filters: {}, isGlobalUrl: true, hasCitySkip: selectedCity?.hasCitySkip });
            await customPush(relativeURL);
            reloadCarListConnect(true);
        } else if (isListingPage) {
            trackEvent(url);
        }
    };

    const renderBudgetFilter = () => {
        if (min === 0) {
            return `Under ${max}`;
        } else if (min >= NUMBER.SIXTY_FIVE_THOSAND) {
            return `Above ${min}`;
        } else {
            return `Between ${min}-${max}`;
        }
    };

    const isLisitingPageFilter = isListingPage && selectedFilters;

    const renderBreadCrumList = (listValue) => {
        return (<li className="breadcrumb-item">
            <span styleName={"styles.linkText"}>
                {listValue}
            </span>
        </li>);

    };

    return (
        <div styleName={"styles.wrap"}>
            <nav aria-label="breadcrumb">
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href={`/`} onClick={(e) => onLinkClickHandler(e, "home", { url: "/" })}>Home</a>
                    </li>
                    {isGlobalUaeUrl && renderBreadCrumList(`Used Cars in UAE`)}
                    {!isGlobalUaeUrl && (
                        <React.Fragment>
                            <li className="breadcrumb-item">
                                <a href={GLOBAL_URL}
                                    onClick={(e) => onLinkClickHandler(e, "globalUrl", { url: GLOBAL_URL })}>
                                    {`Used Cars in UAE`}
                                </a>
                            </li>
                            {(!isLisitingPageFilter && !isDetailsPage) ? renderBreadCrumList(`Used Cars In ${name || DEFAULT_AE_CITY_NAME}`) :
                                <li className="breadcrumb-item">
                                    <a href={`/buy-used-cars-${citySlug}/`}
                                        onClick={(e) => onLinkClickHandler(e, "city", { url: `/buy-used-cars-${citySlug}/` })}>
                                        {`Used Cars In ${name || DEFAULT_AE_CITY_NAME}`}
                                    </a>
                                </li>}
                        </React.Fragment>
                    )}

                    {isLisitingPageFilter && ((FILTER_ENTITIES.MAKE === filterKey && optionKey && !subOptionKey) || FILTER_ENTITIES.BODY_TYPE === filterKey) && optionKey &&
                        renderBreadCrumList(`Used ${optionKey} Cars in ${name || DEFAULT_AE_CITY_NAME}`)
                    }
                    {isLisitingPageFilter && FILTER_ENTITIES.MAKE === filterKey && optionKey && subOptionKey && (
                        !isSubFacetFullySelected ?
                            <li className="breadcrumb-item" aria-current="page">
                                <a href={`/buy-used-${dashedLowercase(optionKey)}-cars-${citySlug}/`}
                                    onClick={(e) =>
                                        onLinkClickHandler(e, "make", { url: `/buy-used-${dashedLowercase(optionKey)}-cars-${citySlug}/` })
                                    }>
                                    Used {optionKey} Cars in {name || DEFAULT_AE_CITY_NAME}
                                </a>
                            </li> :
                            renderBreadCrumList(`Used ${optionKey} Cars in ${name || DEFAULT_AE_CITY_NAME}`))
                    }

                    {isLisitingPageFilter && (FILTER_ENTITIES.MAKE === filterKey) && subOptionKey && !isSubFacetFullySelected &&
                        renderBreadCrumList(`Used ${optionKey} ${subOptionKey} Cars in ${name || DEFAULT_AE_CITY_NAME}`)}

                    {isLisitingPageFilter && (FILTER_ENTITIES.PRICE === filterKey) &&
                        renderBreadCrumList(renderBudgetFilter())}

                    {isDetailsPage && <li className="breadcrumb-item" aria-current="page">
                        <a href={`/buy-used-${dashedLowercase(make)}-cars-${citySlug}/`}
                            onClick={(e) =>
                                onLinkClickHandler(e, "make", { url: `/buy-used-${dashedLowercase(make)}-cars-${citySlug}/` })
                            }>
                            Used {make} Cars in {name || DEFAULT_AE_CITY_NAME}
                        </a>
                    </li>}
                    {isDetailsPage && <li className="breadcrumb-item" aria-current="page">
                        <a href={`/buy-used-${dashedLowercase(make)}-${dashedLowercase(model)}-cars-${citySlug}/`}
                            onClick={(e) =>
                                onLinkClickHandler(e, "make", { url: `/buy-used-${dashedLowercase(make)}-${dashedLowercase(model)}-cars-${citySlug}/` })
                            }>
                            Used {model} Cars in {name || DEFAULT_AE_CITY_NAME}
                        </a>
                    </li>}
                    {isDetailsPage && carName &&
                        renderBreadCrumList(`Used ${carName} in ${name || DEFAULT_AE_CITY_NAME}`)}
                </ul>
            </nav>
        </div >
    );
};

BuyCarBreadCrumbV2.propTypes = {
    selectedCity: PropTypes.object,
    appliedFilters: PropTypes.array,
    content: PropTypes.object,
    reloadCarListConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func
};

export default BuyCarBreadCrumbV2;

import React, {Fragment} from "react";
import styles from "./skeleton.css";
import CarLoader from "./images/car-loader.webp";

const Skeleton = () => {
    return (
        <Fragment>
            <div styleName={"styles.cardSkeleton"}>
                <div styleName={"styles.headingFirst"} className="shimmer" />
                <div styleName={"styles.dFlex"}>
                    <div className="shimmer" styleName={"styles.carImage"}>
                        <img src={CarLoader} alt="" width={125} height={69} />
                        <div className="shimmer" styleName={"styles.headingSecond"}/>
                    </div>
                    <div styleName={"styles.contents"}>
                        <div className="shimmer" styleName={"styles.heading"}/>
                        <div className="shimmer" styleName={"styles.heading"}/>
                        <div className="shimmer" styleName={"styles.heading"}/>
                        <div className="shimmer" styleName={"styles.desc"}/>
                        <div styleName={"styles.dFlex styles.cardFooter"}>
                            <div className="shimmer" styleName={"styles.rectangleShape"} />
                            <div className="shimmer" styleName={"styles.heartIcon"} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Skeleton;

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchCarList, resetCarList, fetchSimilarCarListOnSearch, reloadCarList, updateSSRStatus, toggleSnackBar } from "../car-listing/actions";
import { deleteNotifyOnNoCarFound, fetchCustomRecommendedCars, fetchRecentlyViewedCars, notifyOnNoCarFound, setNudgeGlow, updateAppliedFilters, updateFilterOptionData, updateSelectedSort } from "../filters/actions";
import { setBackToTop } from "../back-to-top/actions";
import { fetchSimilarCars } from "../car-details-v2/actions";
import { getMyBookings } from "../my-bookings/actions";
import { fetchUserDetails } from "../trade-in-interest/actions";
import { setTradeInChoice } from "../checkout-info/actions";

// Component
import CarList from "./component";
import { updateLoanbudgetNudge } from "../../ae.configuration/actions";

const mapStateToProps = ({
    user: { mobile, showLoanBugetNudge},
    config: {
        activeSaleConfig = {},
        showMultiHubBanner
    },
    carListing: {
        totalCars,
        content,
        page,
        isLoading,
        config,
        isSSR,
        shouldReloadList,
        similarCarPage,
        similarCarContent,
        isSimilarCarLoading,
        totalSimilarCars,
        totalSimilarCarPages
    },
    filters: {
        allFilters,
        selectedFilters,
        selectedSuggestions,
        appliedFilters,
        selectedSort,
        urlOptions,
        recentlyViewedCars,
        showNudgeGlow,
        searchResultTrackingData,
        customRecommendedCars = [],
        isCustomRecommendedCarsLoading
    },
    cities: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName,
            hasCitySkip
        } = {}
    },
    carDetails: {
        impressionSource,
        similarCars,
        isSimilarCarsFetching,
        content: carDetailsContent
    },
    compareCars: {
        isComparingActive
    },
    backToTop: {
        show: showBackToTop
    },
    myWishlist: {
        wishlistedCarsList
    },
    loanEligibility: {
        carAvailable
    }
}) => ({
    mobile,
    content,
    totalCars,
    page,
    isLoading,
    config,
    allFilters,
    isSSR,
    selectedCityCode,
    selectedFilters,
    recentlyViewedCars,
    searchResultTrackingData,
    selectedSuggestions,
    urlOptions,
    shouldReloadList,
    impressionSource,
    isComparingActive,
    showBackToTop,
    appliedFilters,
    selectedSort,
    activeSaleConfig,
    similarCars,
    isSimilarCarsFetching,
    showNudgeGlow,
    selectedCityName,
    wishlistedCarsList,
    customRecommendedCars,
    isCustomRecommendedCarsLoading,
    showMultiHubBanner,
    similarCarPage,
    similarCarContent,
    isSimilarCarLoading,
    totalSimilarCars,
    totalSimilarCarPages,
    hasCitySkip,
    showLoanBugetNudge,
    carAvailable,
    carDetailsContent
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarListConnect: fetchCarList,
    updateSSRStatusConnect: updateSSRStatus,
    resetCarListConnect: resetCarList,
    reloadCarListConnect: reloadCarList,
    notifyOnNoCarFoundConnect: notifyOnNoCarFound,
    deleteNotifyOnNoCarFoundConnect: deleteNotifyOnNoCarFound,
    fetchSimilarCarListOnSearchConnect: fetchSimilarCarListOnSearch,
    setBackToTopConnect: setBackToTop,
    updateSelectedSortConnect: updateSelectedSort,
    fetchRecentlyViewedCarsConnect: fetchRecentlyViewedCars,
    fetchSimilarCarsConnect: fetchSimilarCars,
    getMyBookingsConnect: getMyBookings,
    setNudgeGlowConnect: setNudgeGlow,
    fetchUserDetailsConnect: fetchUserDetails,
    setTradeInChoiceConnect: setTradeInChoice,
    fetchCustomRecommendedCarsConnect: fetchCustomRecommendedCars,
    updateFilterOptionDataConnect: updateFilterOptionData,
    updateAppliedFiltersConnect: updateAppliedFilters,
    updateLoanbudgetNudgeConnect: updateLoanbudgetNudge,
    toggleSnackBarConnect: toggleSnackBar
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarList));

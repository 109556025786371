import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HomePageDynamicBanner from "./component";

const mapStateToProps = ({
    config: {
        superAppConfig: {
            topBanner
        } = {}
    }
}) => ({ topBanner
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomePageDynamicBanner);

/* eslint-disable max-statements */
import loadable from "@loadable/component";

// Constants
import { DEFAULT_AE_CITY_NAME, DOWNLOAD_APP_URL, NUMBER } from "../../../constants/app-constants";
import { ROUTE_NAME, ROUTE_PATHS } from "../../../constants/routes-constants";
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import { BANNER_KEY } from "../value-proposition-card-v2/constant";

// Components
import AppDownloadBannerCard from "../app-download-banner-card";

const InterlinkingFilterV2 = loadable(() => import("../interlinking-filter-v2"), {ssr: true});
const CarExchange = loadable(() => import("../car-exchange"));
const ValuePropositionCardV2 = loadable(() => import("../value-proposition-card-v2"));

// Utilities
import { findByKey } from "../../../utils/helpers/find-by-key";

// Tracking
import { getMobileOsType } from "../../../tracking/c2c-tracking-params";
import { b2cHomePageEvents } from "../../../tracking/msite/b2c-home-page-events";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";

export const SHOW_AD_INDEXES = [
    NUMBER.ONE,
    NUMBER.FIVE,
    NUMBER.FOUR,
    NUMBER.NINE,
    NUMBER.ELEVEN,
    NUMBER.TWELVE,
    NUMBER.FOURTEEN,
    NUMBER.SEVENTEEN,
    NUMBER.EIGHTEEN,
    NUMBER.NINETEEN,
    NUMBER.TWENTY_TWO,
    NUMBER.TWENTY_THREE,
    NUMBER.TWENTY_FOUR,
    NUMBER.TWENTY_SEVEN,
    NUMBER.TWENTY_NINE,
    NUMBER.THIRTY,
    NUMBER.THIRTY_FOUR,
    NUMBER.THIRTY_NINE,
    NUMBER.FOURTY_FOUR,
    NUMBER.FOURTY_NINE,
    NUMBER.FIFTY_FOUR,
    NUMBER.FIFTY_NINE
];

const FILTER_FINANCE = {
    Component: InterlinkingFilterV2,
    props: {
        title: "Get cars within your loan Budget",
        index: 4
    }
};

const FILTER_BUDGET = {
    Component: InterlinkingFilterV2,
    props: {
        title: "Budget friendly cars",
        index: 1,
        chipFilterKey: FILTER_ENTITIES.PRICE
    }
};

const FILTER_MAKE = {
    Component: InterlinkingFilterV2,
    props: {
        title: "Looking for a specific Brand",
        index: 2,
        chipFilterKey: FILTER_ENTITIES.MAKE
    }
};

const FILTER_MODEL = {
    Component: InterlinkingFilterV2,
    props: {
        title: "Car models that you may like",
        index: 16,
        chipFilterKey: FILTER_ENTITIES.MODEL
    }
};

const FILTER_BODY_TYPE = {
    Component: InterlinkingFilterV2,
    props: {
        title: "Top Bodytype",
        index: 3,
        chipFilterKey: FILTER_ENTITIES.BODY_TYPE
    }
};

export const INTER_LINKING_FILTERS = {
    [NUMBER.FIVE]: { list: [FILTER_FINANCE] },
    [NUMBER.ELEVEN]: { list: [FILTER_MODEL] },
    [NUMBER.SEVENTEEN]: { list: [FILTER_MAKE] },
    [NUMBER.TWENTY_THREE]: { list: [FILTER_BUDGET] },
    [NUMBER.TWENTY_NINE]: { list: [FILTER_BODY_TYPE] }
};

// eslint-disable-next-line complexity
export const getInterLinkingFilters = (appliedFilters, isSeoPath = false, selectedCityName = DEFAULT_AE_CITY_NAME) => {
    const positions = [NUMBER.FIVE, NUMBER.ELEVEN, NUMBER.SEVENTEEN, NUMBER.TWENTY_THREE, NUMBER.THIRTY];
    const filterArr = [
        { key: FILTER_ENTITIES.FINANCE, component: { list: [FILTER_FINANCE] } },
        { key: FILTER_ENTITIES.MODEL, component: { list: [FILTER_MODEL] } },
        ...(selectedCityName === DEFAULT_AE_CITY_NAME ? [{ key: FILTER_ENTITIES.MAKE, component: { list: [FILTER_MAKE] } }] : []),
        { key: FILTER_ENTITIES.PRICE, component: { list: [FILTER_BUDGET] } },
        { key: FILTER_ENTITIES.BODY_TYPE, component: { list: [FILTER_BODY_TYPE] } }
    ];

    let positionIdx = 0;
    let availableFilters = {};

    const checkIfFilterAvailable = (filterIndex) => appliedFilters.find(filter => filter.filterKey === filterArr[filterIndex].key);

    for (let filterIdx = 0; filterIdx < filterArr.length; filterIdx++) {
        if (checkIfFilterAvailable(filterIdx) && !isSeoPath) {
            continue;
        }
        if (filterArr[filterIdx].key === FILTER_ENTITIES.PRICE) {
            const showPriceChanges = isSeoPath && !!(appliedFilters || []).find(filter => filter.filterKey === FILTER_ENTITIES.MAKE);
            filterArr[filterIdx].component.list[NUMBER.ZERO].props.title = showPriceChanges ?
                `${findByKey(appliedFilters, "filterKey", FILTER_ENTITIES.MAKE)?.displayText} used cars by Price` :
                `Used cars in ${selectedCityName} by Price`;
        } else if (filterArr[filterIdx].key === FILTER_ENTITIES.MAKE) {
            filterArr[filterIdx].component.list[NUMBER.ZERO].props.title = `Used cars in ${selectedCityName} by brand`;
        } else if (filterArr[filterIdx].key === FILTER_ENTITIES.BODY_TYPE) {
            filterArr[filterIdx].component.list[NUMBER.ZERO].props.title = `Used cars in ${selectedCityName} by body type`;
        }

        availableFilters = {
            ...availableFilters,
            [positions[positionIdx]]: filterArr[filterIdx].component
        };
        positionIdx++;
    }

    return availableFilters;
};

const VALUE_PROPOSITION_CARD_PROPS = {
    "EPIC_SALE": {
        bannerKey: BANNER_KEY.EPIC_SALE,
        page: ROUTE_NAME.LISTING
    },
    "APP_DOWNLOAD": {
        bannerKey: getMobileOsType.android() ? BANNER_KEY.ANDROID : BANNER_KEY.IOS,
        ctaProps: {
            isCta: true,
            isExternalLink: true,
            route: DOWNLOAD_APP_URL
        }
    },
    "HOME_TD_ALL": {
        bannerKey: BANNER_KEY.HOME_TD_ALL,
        ctaProps: {
            isCta: false
        }
    },
    "SELL_CAR": {
        bannerKey: BANNER_KEY.SELL_CAR,
        ctaProps: {
            isCta: true
        }
    },
    "ZERO_DOWNPAYMENT": {
        bannerKey: BANNER_KEY.ZERO_DOWNPAYMENT,
        ctaProps: {
            isCta: true,
            isExternalLink: true,
            route: `/${ROUTE_PATHS.carLoan}`,
            eventName: "b2cZeroDPListingPage"
        }
    }
    // "PUSH_FP_UNLOCK_BANNER": {
    //     bannerKey: BANNER_KEY.PUSH_FP_UNLOCK_BANNER,
    //     ctaProps: {
    //         isCta: true
    //     }
    // }
};

export const VALUE_PROPOSITION_CARDS = {
    [NUMBER.ONE]: {
        list: [
            {
                Component: AppDownloadBannerCard,
                props: VALUE_PROPOSITION_CARD_PROPS?.APP_DOWNLOAD
            }
        ]
    },
    // [NUMBER.FOUR]: {
    //     list: [
    //         {
    //             Component: FullPaymentBanner,
    //             props: VALUE_PROPOSITION_CARD_PROPS?.PUSH_FP_UNLOCK_BANNER
    //         }
    //     ]
    // },
    [NUMBER.FOUR]: {
        list: [
            {
                Component: ValuePropositionCardV2,
                props: VALUE_PROPOSITION_CARD_PROPS?.HOME_TD_ALL
            }
        ]
    },
    [NUMBER.NINE]: {
        list: [
            {
                Component: ValuePropositionCardV2,
                props: VALUE_PROPOSITION_CARD_PROPS?.ZERO_DOWNPAYMENT
            }
        ]
    },
    [NUMBER.FOURTEEN]: {
        list: [
            {
                Component: CarExchange,
                props: VALUE_PROPOSITION_CARD_PROPS?.SELL_CAR
            }
        ]
    }
};

export const DEFAULT_SORT = "releavance";
export const SEARCH_SORT = "releavance";
export const EXACT_SEARCH_SORT = "search-releavance";

export const GA_EVENT_MAP = {
    [ROUTE_NAME.HOME]: b2cHomePageEvents.homeSearchResultClick,
    [ROUTE_NAME.LISTING]: b2cListingPageEvents.listingSearchResultClick
};

export const LISTING_SECTION_NAME = {
    RECENTLY_VIEWED_CARS_SECTION: "recentlyViewedCarsSection",
    WISHLISTED_SIMILAR_CARS_SECTION: "wishlistedSimilarCarsSection",
    RECOMMENDED_CARS_SECTION: "recommendedCarsSection",
    SEARCH_SIMILAR_CARS_SECTION: "searchSimilarCarsSection",
    CUSTOM_RECOMMENDED_CARS_SECTION: "customRecommendedCarsSection"
};

export const PRELOAD_INDEX = [NUMBER.ZERO, NUMBER.ONE];

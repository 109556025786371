import React, {Fragment} from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Component
import FilterDropdown from "../filter-dropdown";
import PageLoader from "../../shared/page-loader";
const FilterModal = loadable(() => import("../filter-modal"), { fallback: <PageLoader /> });

const OnScreenFilter = ({
    selectedOnScreenFilter,
    onFilterSelection,
    isTabNavTriggered,
    showCallButton,
    msiteSaveSelectedFilters
}) => {
    return (
        <Fragment>
            <FilterDropdown onFilterSelection={onFilterSelection} isTabNavTriggered={isTabNavTriggered} showCallButton={showCallButton} />
            { selectedOnScreenFilter && <FilterModal msiteSaveSelectedFilters={msiteSaveSelectedFilters} /> }
        </Fragment>
    );
};

OnScreenFilter.propTypes = {
    selectedOnScreenFilter: PropTypes.string,
    onFilterSelection: PropTypes.func,
    isTabNavTriggered: PropTypes.bool,
    showCallButton: PropTypes.bool,
    msiteSaveSelectedFilters: PropTypes.string
};

export default OnScreenFilter;

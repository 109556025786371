import React, { useState } from "react";

// Styles
import styles from "./styles.css";

// Images
import AndroidBanner from "./images/android.png";
import IosBanner from "./images/ios.png";

// Tracking
import { getMobileOsType } from "../../../tracking/c2c-tracking-params";

// Components
import PageLoader from "../../shared/page-loader";

// Constants
import { DOWNLOAD_APP_URL, NUMBER } from "../../../constants/app-constants";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";

const AppDownloadBannerCard = () => {

    const [showPageLoader, setShowPageLoader] = useState(false);

    const handleRedirect = async (e) => {
        e.preventDefault();
        setShowPageLoader(true);
        await yieldToMainThread();
        trackPlatformCustomEventsAEV2(b2cListingPageEvents.listingPageAppDownloadBannerClick);
        window.setTimeout(() => {
            window.location.href = DOWNLOAD_APP_URL;
            setShowPageLoader(false);
        }, NUMBER.THOUSAND);
    };

    return (
        <React.Fragment>
            <div styleName={"styles.wrapper"} style={{minHeight: "152px"}}>
                <div styleName={"styles.bannerCard"}>
                    {getMobileOsType.android() &&
                        <a href={DOWNLOAD_APP_URL} onClick={handleRedirect} styleName={"styles.bannerOuter"}>
                            <p styleName={"styles.titleText"}>Get the Best CARS24 Experience</p>
                            <p>Get best offers & Updates!</p>
                            <img src={AndroidBanner} alt="Get the best CARS24 experience" width={320} height={131} />
                        </a>
                    }
                    {getMobileOsType.ios() && (
                        <a href={DOWNLOAD_APP_URL} onClick={handleRedirect} styleName={"styles.bannerOuter"}>
                            <p styleName={"styles.titleText"}>Get the Best CARS24 Experience</p>
                            <p>Get best offers & Updates!</p>
                            <img src={IosBanner} alt="Get the best CARS24 experience" width={320} height={131} />
                        </a>
                    )}
                </div>
            </div>
            {showPageLoader && <PageLoader/> }
        </React.Fragment>
    );
};

export default AppDownloadBannerCard;

/* eslint-disable no-unused-expressions */
import Types from "./types";
import { CheckoutAEService } from "../../../service";

const getMyBookingsInitiated = () => ({
    type: Types.GET_MY_BOOKINGS_INITIATED
});

const refreshMyBookingsInitiated = () => ({
    type: Types.REFRESH_MY_BOOKINGS_INITIATED
});

const getMyBookingsSuccess = (data, params, isSnackCard) => ({
    type: Types.GET_MY_BOOKINGS_SUCCESS,
    data,
    params,
    isSnackCard
});

const getMyBookingsFailure = (error) => ({
    type: Types.GET_MY_BOOKINGS_FAILURE,
    error
});

const getMyBookings = (token, params = {}, state = { isRefresh: false, isSnackCard: false}) => (dispatch) => {
    state.isRefresh ? dispatch(refreshMyBookingsInitiated()) : dispatch(getMyBookingsInitiated());
    CheckoutAEService.getMyBookings(token, params).then((response) => {
        dispatch(getMyBookingsSuccess(response.data, params, state.isSnackCard));
    }).catch((error) => {
        dispatch(getMyBookingsFailure(error));
    });
};

const getPendingBookingsInitiated = () => ({
    type: Types.GET_PENDING_BOOKINGS_INITIATED
});

const getPendingBookingsSuccess = (payload, isSnackCard) => ({
    type: Types.GET_PENDING_BOOKINGS_SUCCESS,
    payload,
    isSnackCard
});

const getPendingBookingsFailure = (error) => ({
    type: Types.GET_PENDING_BOOKINGS_FAILURE,
    error
});

const getPendingBookings = (token, params = {}, state = { isRefresh: false, isSnackCard: false}) => (dispatch) => {
    state.isRefresh ? dispatch(refreshMyBookingsInitiated()) : dispatch(getPendingBookingsInitiated());
    CheckoutAEService.getMyBookings(token, params).then((response) => {
        dispatch(getPendingBookingsSuccess(response.data, state.isSnackCard));
    }).catch((error) => {
        dispatch(getPendingBookingsFailure(error));
    });
};

const cancelBookingInitiated = () => ({
    type: Types.BOOKING_CANCELLATION_INITIATED
});

const cancelBookingSuccess = (data) => ({
    type: Types.BOOKING_CANCELLATION_SUCCESS,
    data
});

const cancelBookingFailure = (error) => ({
    type: Types.BOOKING_CANCELLATION_FAILURE,
    error
});

const cancelBooking = (orderId, token, cancelReason) => (dispatch) => {
    dispatch(cancelBookingInitiated());
    return new Promise((resolve, reject) => {
        CheckoutAEService.cancelOrder(orderId, token, cancelReason).then((response) => {
            dispatch(cancelBookingSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(cancelBookingFailure(error));
            reject(error);
        });
    });
};

const updateSelectedCar = (carId) => ({
    type: Types.UPDATE_SELECTED_CAR,
    carId
});

export {
    getMyBookings,
    cancelBooking,
    updateSelectedCar,
    getPendingBookings
};

/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useEffect, useState, Fragment } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { b2cAllPageEvents } from "../../../tracking/msite/b2c-all-page-events";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";

// Hooks
import useScrollDirection from "../../../hooks/scroll-direction";
import useToggle from "../../../hooks/use-toggle";

// Components
import CarList from "../car-list";
import withLogin from "../with-login";
import OnScreenFilter from "../on-screen-filter";
import BuyCarBreadCrumbV2 from "../buy-car-breadcrumb-v2";
import ShowHeader from "../show-header";
import CarListingFaq from "../../shared/car-listing-faq";
import MetaTagsCarListingV2 from "../../shared/meta-tags-car-listing-v2";
import Skeleton from "./skeleton";
import PageLoader from "../../shared/page-loader";
const NotFound = loadable(() => import("../../shared/page-not-found-ae"));
import LoanBudgetCta from "../loan-budget-cta";
const CompareWidget = loadable(() => import("../compare-widget"), { fallback: <PageLoader /> });
// const FeedbackFloating = loadable(() => import("../feedback-floating"));
const BottomNavigation = loadable(() => import("../bottom-navigation"));

// Constant
import { FEEDBACK_TYPE, FEEDBACK_CONFIG } from "../feedback/constant";
import { ROUTE_NAME, ROUTE_PATHS } from "../../../constants/routes-constants";
import { COOKIE_CONSTANTS, DEFAULT_CITY, HTTP_CODES, NUMBER, LOCATION_SOURCE, AB_TEST_VARIATIONS } from "../../../constants/app-constants";
// import { HIDE_FEEDBACK_MODAL_DAYS } from "../../../constants/ae/post-booking-constants";
import { appUrl } from "../../../constants/url-constants";

// Utilities
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import saveCookie from "../../../utils/helpers/save-cookie";
import parseCookie from "../../../utils/helpers/parse-cookie";
import saveSession from "../../../utils/helpers/save-session";
import parseSession from "../../../utils/helpers/parse-session";
// import isFirstTimeVisitInDay from "../../../utils/helpers/get-first-time-visit-from-cookie";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
import SeoLinksListingPageV2 from "../../shared/seo-links-listing-page-v2/index";
import DownloadAppNudge from "../download-app-nudge";
import AiBotIcon from "../ai-bot-icon";
import ChatIcon from "../chat-icon";
import { EXPERIMENT_NAME } from "../../../utils/statsig-ab-utils/constant";
const SnackBar = loadable(() => import("../snack-bar"));
import useExperiments from "../../../hooks/use-statsig-experiment";
import { deepCompareRecursive } from "../../../utils/helpers/deep-compare-recursive";
import LazyComponent from "../../shared/lazy-component";
import HomeBannerSection from "../home-banner-section";
import HomePageDynamicBanner from "../home-page-dynamic-banner";

const CarListing = ({
    secureToken,
    fetchFiltersConnect,
    isFilterSSR,
    updateFilterSSRStatusConnect,
    selectedCity,
    updateLocationPopupShownConnect,
    shouldReloadList,
    metaContent,
    isLoading,
    selectedCarsToCompare,
    activeSaleConfig,
    isComparingActive,
    fetchUserFeedbackConnect,
    isLoggedIn,
    setFeedbackCarDetailContentConnect,
    route,
    updateSelectedOnScreenFilterConnect,
    error,
    totalCars,
    content,
    imageHost,
    appliedFilters,
    similarCars,
    bookings,
    fetchingSnackCards,
    snackCardsFetched,
    showSnackBar,
    showDownloadNudge,
    updateDownloadNudgeStatusConnect,
    msiteSaveSelectedFilters,
    newFilterSelected,
    savedFilters,
    fetchSavedFiltersConnect,
    superAppConfig,
    getStatsigDynamicConfigConnect
}) => {
    const match = useRouteMatch();
    const location = useLocation();
    const [tdFeedbackModal, setTDFeedbackModal] = useState({ show: false, data: {} });
    const [showAllText, setShowAllText] = useToggle(false);
    const isTabNavTriggered = useScrollDirection({ threshold: 68 });
    const [isStickySearch, setIsStickySearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [recentlyViewedTotal, setRecentlyViewedTotal] = useState(0);
    const [isBottomNavVisible, setIsBottomNavVisible] = useState(false);
    const tdFeedbackCookieName = FEEDBACK_CONFIG[FEEDBACK_TYPE.TEST_DRIVE]?.cookieName;
    const hideTDFeedback = parseCookie(tdFeedbackCookieName) ? JSON.parse(parseCookie(tdFeedbackCookieName)) : {};
    const queryParams = parseLocationSearchParams(location.search);
    const isRecentlyViewed = queryParams?.isRecentlyViewed;
    const wishlistSimilarAppID = queryParams?.wsAppId || "";
    const similarCarsCount = (similarCars || [])?.length || NUMBER.ZERO;
    const { h1Tag, additionalData = {} } = metaContent || {};
    const sessionToggleEmi = parseSession("toggleEmi");
    const [toggleEmi, setToggleEmi] = useState(sessionToggleEmi || false);
    const { cars: snackCards = [] } = bookings || {};
    const isSaveFiltersEnabled = msiteSaveSelectedFilters === AB_TEST_VARIATIONS.VARIANT;
    const isShowSnackBar = (!fetchingSnackCards && snackCardsFetched && snackCards?.length > NUMBER.ZERO) || (activeSaleConfig?.active && !isLoggedIn && parseCookie(COOKIE_CONSTANTS.BOTTOM_SALE_NUDGE_NOTIFIER_SHOWN));

    const { customPush } = useCustomHistoryOperations();
    const [showSaveSnackbar, setShowSaveSnackbar] = useState(false);

    const isSeoPath = !Object.keys(queryParams)?.length;
    useExperiments({ experimentKeys: [EXPERIMENT_NAME.msite_save_selected_filters] });
    useEffect(() => {
        setToggleEmi(sessionToggleEmi);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionToggleEmi]);

    const onCityChange = async (e) => {
        setLoading(true);
        e.preventDefault();
        await customPush(`/location-capture/?from=${LOCATION_SOURCE.LISTING}`);
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.locationPageShown, { eventLabel: "listing_change" });
    };

    const onScrollPageFilter = (inView) => {
        setIsStickySearch(inView ? false : true);
    };

    useEffect(() => {
        if (isLoggedIn && isSaveFiltersEnabled) {
            fetchSavedFiltersConnect();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    const stableSort = (array = []) => {
        return (array || []).sort((a, b) => {
            let displayTextDiff;
            if (a?.displayText) {
                displayTextDiff = a?.displayText?.localeCompare(b?.displayText); // Locale-aware comparison
            } else displayTextDiff = a?.filterKey?.localeCompare(b?.filterKey);
          return displayTextDiff === 0 ? a?.filterKey - b?.filterKey : displayTextDiff;
        });
      };

    const checkNewFilter = () => {
        if (appliedFilters?.length > 0 && savedFilters?.length > 0) {
            const existedFilter = savedFilters.find(v => {
                return deepCompareRecursive(stableSort(v.queryString ? JSON.parse(v.queryString) : []), stableSort(appliedFilters));
            });
            return existedFilter ? false : true;
        } else if (appliedFilters?.length && !savedFilters?.length) {
            return true;
        } else return false;
    };

    const checkForSearchText = () => {
        if (queryParams?.search && savedFilters?.length > 0) {
            const existedSearch = savedFilters.find(v => v.searchText === queryParams?.search);
            return existedSearch ? false : true;
        } else return false;
    };

    useEffect(() => {
        if (isLoggedIn && isSaveFiltersEnabled && ((checkNewFilter() && appliedFilters?.length > 0) || (queryParams?.search && checkForSearchText()))) {
            setShowSaveSnackbar(true);
        } else if (showSaveSnackbar) setShowSaveSnackbar(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedFilters, appliedFilters]);

    useEffect(() => {
        setIsStickySearch(false);
    }, [shouldReloadList]);

    useEffect(() => {
        if (showDownloadNudge) {
                saveCookie(COOKIE_CONSTANTS.FIRST_TIME_VISIT_IN_A_DAY, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDownloadNudge]);

    const updateFilterModal = async () => {
        updateSelectedOnScreenFilterConnect("make");
    };

    const fetchUserFeedback = () => {
        fetchUserFeedbackConnect({ feedbackType: FEEDBACK_TYPE.TEST_DRIVE }, secureToken).then(async ({ tdFeedback = [] }) => {
            if ((tdFeedback || []).length > 0) {
                const tdFeedbackData = tdFeedback.find((carDetail) => {
                    const { orderId } = carDetail;
                    return !hideTDFeedback[orderId];
                });
                if (tdFeedbackData) {
                    // await yieldToMainThread();
                    setFeedbackCarDetailContentConnect(tdFeedbackData);
                    setTDFeedbackModal({ ...tdFeedbackModal, show: true, data: tdFeedbackData });
                }
            }
        });
    };
    const updateFilterSSR = async () => {
        if (!isFilterSSR) {
            try {
                const filterParams = {
                    variant: "filterV5"
                };
                fetchFiltersConnect(filterParams);
            } catch (err) {
                // Handle if any error
            }
        }
        // await yieldToMainThread();
        updateFilterSSRStatusConnect(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleInitEffectData = async () => {
        if (queryParams.source === "makeFilter") {
            updateFilterModal();
        }
        // await yieldToMainThread();
        if (isLoggedIn) {
            fetchUserFeedback();
        }
        updateFilterSSR();
    };

    useEffect(() => {
        handleInitEffectData();
        return () => {
            updateSelectedOnScreenFilterConnect("");
            updateLocationPopupShownConnect(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (superAppConfig?.isDefaultConfig) {
            getStatsigDynamicConfigConnect("msite_super_app_homepage ");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (error?.error === "Invalid s-path" || error?.error === HTTP_CODES.NOT_FOUND) {
        return <NotFound />;
    }

    const getTotalVehicleCount = () => {
        if (isRecentlyViewed) {
            return recentlyViewedTotal;
        } else if (wishlistSimilarAppID) {
            return similarCarsCount;
        } else {
            return totalCars;
        }
    };

    const getPriceLabel = (price) => {
        if (price < NUMBER.THOUSAND) {
            return Math.round(price);
        }
        const nearestRoundOff = Math.round(price / NUMBER.THOUSAND);
        return `${nearestRoundOff}k`;
    };

    const handleLoanRoute = async (step = "") => {
        setLoading(true);
        await customPush(`/${ROUTE_PATHS.carLoan}${step && `?goTo=${step}`}`);
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.carAffordabilityCarLoanEntry, { eventLabel: "loan_eligibility_new_comp" });
        trackPlatformCustomEventsAEV2(b2cListingPageEvents.carAffordabilityListingComp, { eventLabel: step ? "edit" : "check_now" });
    };

    const handleToggleChange = async () => {
        setToggleEmi(!toggleEmi);
        saveSession("toggleEmi", !toggleEmi);
        await yieldToMainThread(NUMBER.FIVE_HUNDRED);
        trackPlatformCustomEventsAEV2(b2cListingPageEvents.carAffordabilityLoanToggle, { eventLabel: toggleEmi ? "manual_off" : "manual_on" });
    };

    const handleDownloadAppNudgeOnClickHandler = async ({ ctaType }) => {
        await yieldToMainThread();
        updateDownloadNudgeStatusConnect(false);
        trackPlatformCustomEventsAEV2(b2cListingPageEvents.appDownloadBottomDrawerListingPage, { eventLabel: ctaType });
    };

    const showSaveFilterSnackbar = (isLoggedIn && isSaveFiltersEnabled && showSaveSnackbar) || (!isLoggedIn && isSaveFiltersEnabled);
    return (
        <Fragment>
            <MetaTagsCarListingV2
                cityName={selectedCity.name || DEFAULT_CITY.AE.city_name}
                metaContent={metaContent}
                location={location}
                match={match}
                content={content}
                imageHost={imageHost}
                appliedFilters={appliedFilters}
            />
            <BottomNavigation
                isVisibleCB={setIsBottomNavVisible}
                routeName={ROUTE_NAME.LISTING}
            />
            <InView
                as="div"
                rootMargin={"0px 0px 0px 0px"}
                onChange={onScrollPageFilter}
            />
            <div styleName={"styles.homeBannerSectionWrap"}>
                <HomeBannerSection selectedCity={selectedCity}  source={ROUTE_NAME.LISTING}/>
            </div>
            {superAppConfig?.topBanner?.showTopBannerLp && <div styleName={"styles.dynamicBannerSectionWrap"}>
                <HomePageDynamicBanner />
            </div>}
            <Fragment>
                <div
                    styleName={`styles.filterSearchWrap ${isTabNavTriggered ? "" : "styles.boxShadow"}`}
                >
                    <OnScreenFilter
                        isTabNavTriggered={isTabNavTriggered}
                        showCallButton={isStickySearch}
                    />
                </div>
                <LoanBudgetCta
                    handleLoanRoute={handleLoanRoute}
                    handleToggleChange={handleToggleChange}
                    toggleEmi={toggleEmi}
                />
                <AiBotIcon
                    route={{ name: ROUTE_NAME.LISTING }}
                    isBottomNavVisible={isBottomNavVisible}
                    isAnotherCTAVisible={(isLoggedIn && isShowSnackBar && appliedFilters.length === NUMBER.ZERO) || isShowSnackBar || showSaveFilterSnackbar}
                />
                <ChatIcon
                    route={{ name: ROUTE_NAME.LISTING }}
                    isBottomNavVisible={isBottomNavVisible}
                    isAnotherCTAVisible={(isLoggedIn && isShowSnackBar && appliedFilters.length === NUMBER.ZERO) || isShowSnackBar || showSaveFilterSnackbar}
                />
                {(isShowSnackBar || (isSaveFiltersEnabled)) && (
                    <LazyComponent>
                        <SnackBar
                            show={(isLoggedIn && showSnackBar && appliedFilters.length === NUMBER.ZERO) || (isShowSnackBar) ||
                                showSaveFilterSnackbar}
                            isBottomNavVisible={isBottomNavVisible}
                            appliedFilters={appliedFilters}
                            search={queryParams?.search}
                            showSaveSnackbar={showSaveSnackbar}
                            snackCards={snackCards}
                            isSaveFiltersEnabled={isSaveFiltersEnabled}
                            newFilterSelected={newFilterSelected}
                            savedFilters={savedFilters}
                            source={ROUTE_NAME.LISTING}
                        />
                    </LazyComponent>
                )}
                <div>
                    <div styleName={"styles.carListingWrap"}>
                        {!isLoading ? (
                            <Fragment>
                                <div styleName={"styles.headerSortWrap"}>
                                    <ShowHeader
                                        totalCars={getTotalVehicleCount()}
                                        onChange={onCityChange}
                                        text={h1Tag}
                                    />
                                </div>
                                {(additionalData?.structuredDescription || additionalData?.description) && (
                                    <div styleName="containerWrap">
                                        <div styleName={`styles.seoDescriptionWrap ${showAllText ? "styles.mbTwentyFour" : ""}`}>
                                            <div styleName={!showAllText ? "styles.truncatedWrap" : "styles.paraMargin"} dangerouslySetInnerHTML={{ __html: additionalData?.structuredDescription || additionalData?.description }} />
                                            <div style={{ visibility: showAllText ? "visible" : "hidden", height: showAllText ? "auto" : NUMBER.ZERO }}>
                                                {additionalData?.makeBasedDescription?.EN &&
                                                    <p styleName={"styles.paraMargin"} dangerouslySetInnerHTML={{ __html: additionalData?.makeBasedDescription?.EN }} />
                                                }
                                                {additionalData?.makeBasedDescription?.AR &&
                                                    <p styleName={"styles.arabicContent"}>{additionalData?.makeBasedDescription?.AR}</p>
                                                }
                                                {!!additionalData?.topCars && (
                                                    <Fragment>
                                                        <div style={{ visibility: showAllText ? "visible" : "hidden", height: showAllText ? "auto" : NUMBER.ZERO }}>
                                                            <div styleName={"styles.recentlyAddedHeader"}>
                                                                <span>{additionalData?.topCars?.heading}</span>
                                                            </div>
                                                            <div styleName={"styles.recentlyAddedWrap"}>
                                                                {additionalData?.topCars?.data.map(
                                                                    (item) => (
                                                                        <div styleName={"styles.dFlex"} key={`${item?.make}_${item?.model}`}>
                                                                            <a
                                                                                href={`${appUrl()}/${item?.url}`}
                                                                                title={`Used ${item?.make} ${item?.model || ""} cars in Dubai`}
                                                                            >Used {item?.make}{item?.model ? ` ${item.model}` : ""} cars in Dubai</a>
                                                                            <p>
                                                                                {item.numberOfCars && <span>{item.numberOfCars} cars</span>}
                                                                                {(item?.minPrice !== null && item?.maxPrice !== null) && <span>{!!item.numberOfCars && ", "}AED {getPriceLabel(item.minPrice)} - {getPriceLabel(item.maxPrice)}</span>}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </div>
                                        {((additionalData?.structuredDescription?.length > NUMBER.FIFTY_SIX) || (additionalData?.description?.length > NUMBER.FIFTY_SIX)) && (
                                            <span styleName={`styles.readMore ${showAllText ? "styles.readLess" : ""}`} onClick={setShowAllText}>
                                                {showAllText ? "READ LESS" : "READ MORE"}
                                            </span>
                                        )}
                                    </div>
                                )}
                            </Fragment>
                        ) : (
                            <div styleName={"styles.shimmerCtn"}>
                                <Skeleton />
                            </div>
                        )}

                        <CarList
                            route={route}
                            setRecentlyViewedTotal={setRecentlyViewedTotal}
                            toggleEmi={toggleEmi}
                        />
                        <div styleName={"styles.trackPattern"} />
                    </div>
                </div>
            </Fragment>
            <section className="container" styleName={"styles.faqWrapper"}>
                <CarListingFaq cityName={selectedCity.name || DEFAULT_CITY.AE.city_name} routeMatch={match} />
            </section>
            {isSeoPath && <SeoLinksListingPageV2 pageSource={ROUTE_NAME.LISTING} />}
            <div styleName={"styles.breadcrumbWrap"}>
                <BuyCarBreadCrumbV2 />
            </div>
            {isComparingActive && selectedCarsToCompare && <CompareWidget source="listing" />}
            {/* {tdFeedbackModal.show && <FeedbackFloating onClose={handleFeedbackModalClose} data={tdFeedbackModal.data} />} */}
            {showDownloadNudge && <DownloadAppNudge onClickHandler={handleDownloadAppNudgeOnClickHandler} source={ROUTE_NAME.LISTING} />}
            {loading && <PageLoader />}
        </Fragment>
    );
};

CarListing.propTypes = {
    fetchFiltersConnect: PropTypes.func,
    isFilterSSR: PropTypes.bool,
    updateFilterSSRStatusConnect: PropTypes.func,
    selectedCity: PropTypes.object,
    secureToken: PropTypes.string,
    updateLocationPopupShownConnect: PropTypes.func,
    shouldReloadList: PropTypes.bool,
    metaContent: PropTypes.object,
    selectedCarsToCompare: PropTypes.number,
    isComparingActive: PropTypes.bool,
    fetchUserFeedbackConnect: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    setFeedbackCarDetailContentConnect: PropTypes.func,
    isLoading: PropTypes.bool,
    route: PropTypes.object,
    updateSelectedOnScreenFilterConnect: PropTypes.func,
    error: PropTypes.object,
    totalCars: PropTypes.number,
    content: PropTypes.array,
    imageHost: PropTypes.string,
    appliedFilters: PropTypes.array,
    similarCars: PropTypes.array,
    activeSaleConfig: PropTypes.object,
    bookings: PropTypes.object,
    fetchingSnackCards: PropTypes.bool,
    snackCardsFetched: PropTypes.bool,
    showSnackBar: PropTypes.bool,
    showDownloadNudge: PropTypes.bool,
    updateDownloadNudgeStatusConnect: PropTypes.func,
    msiteSaveSelectedFilters: PropTypes.string,
    newFilterSelected: PropTypes.bool,
    savedFilters: PropTypes.array,
    fetchSavedFiltersConnect: PropTypes.func,
    superAppConfig: PropTypes.object,
    getStatsigDynamicConfigConnect: PropTypes.func
};

export default withLogin(CarListing);

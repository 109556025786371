import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

// Styles
import styles from "./styles.css";

// Components
import AppliedFilterTagV2 from "../../shared/applied-filter-tag-v2";

// Utilities
import Filters from "../../../utils/filters-v2";
import getUpdatedAppliedFilters from "../../../utils/filters-v2/get-updated-applied-filters";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import saveCookie from "../../../utils/helpers/save-cookie";
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import parseSession from "../../../utils/helpers/parse-session";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";

// Constant
import { COOKIE_CONSTANTS, CURRENCY, NUMBER } from "../../../constants/app-constants";

// Hooks
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

// eslint-disable-next-line max-statements
const AppliedFiltersV2 = ({
    allFilters,
    updateFilterOptionDataConnect,
    selectedCityName,
    selectedCityCode,
    reloadCarListConnect,
    appliedFilters,
    selectedSort,
    updateAppliedFiltersConnect,
    updateSelectedSuggestionsConnect,
    urlOptions,
    setComparingActiveConnect
}) => {

    const location = useLocation();

    const queryParams = parseLocationSearchParams(location.search);
    const searchQuery = queryParams.search;
    const [removedFilter, setRemovedFilter] = useState(false);
    const { customReplace } = useCustomHistoryOperations();

    const hideCompareMode = async () => {
        await yieldToMainThread();
        setComparingActiveConnect(false);
    };

    const onRemoveFilter = async (data) => {
        setRemovedFilter(true);
        await yieldToMainThread();
        const updatedFilters = getUpdatedAppliedFilters(data, appliedFilters);
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { relativeURL } = filters.getListingPayload({
            selectedFilters: updatedFilters, filters: allFilters, options: {
                ...urlOptions,
                ...(
                    searchQuery && {
                        search: searchQuery
                    }
                )
            }
        });
        await yieldToMainThread();
        updateFilterOptionDataConnect(data);
        updateAppliedFiltersConnect();
        updateSelectedSuggestionsConnect(data);
        await customReplace(relativeURL);
        reloadCarListConnect(true);
        await yieldToMainThread();
        saveCookie(COOKIE_CONSTANTS.LISTING_FILTER_NUDGE_SHOWN, true, NUMBER.SEVEN);
    };

    useEffect(() => {
        if (removedFilter && appliedFilters.length < 1 && !searchQuery) {
            hideCompareMode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedFilters.length, searchQuery, removedFilter]);

    const isShowChip = (filterKey = "") => {
        const toggleEmi = parseSession("toggleEmi");
        const ignoreFilters = [FILTER_ENTITIES.PRICE, FILTER_ENTITIES.FINANCE];
        const isShowFilterChip = ignoreFilters.includes(filterKey) && toggleEmi;
        return !isShowFilterChip;
    };

    const renderTags = () => (
        <React.Fragment>
            {
                appliedFilters.reverse().map((appliedFilter, index) => {
                    const makeFilter = {
                        filterKey: appliedFilter.filterKey,
                        optionKey: appliedFilter.optionKey,
                        displayText: appliedFilter.optionKey
                    };
                    return (appliedFilter.isSubFacetFullySelected) ?
                        appliedFilter.key === "make" && appliedFilter.filterKey === "make" &&
                        <li styleName={"styles.filterApplied2"} key={index}>
                            <AppliedFilterTagV2
                                key={`appliedFilters_${index}`}
                                onRemove={onRemoveFilter}
                                filter={makeFilter}
                                currency={CURRENCY.AE}
                                allFilters={allFilters}
                            />
                        </li> :
                        (isShowChip(appliedFilter.filterKey) && (
                            <li styleName={"styles.filterApplied2"} key={index}>
                                <AppliedFilterTagV2
                                    key={`appliedFilters_${index}`}
                                    onRemove={onRemoveFilter}
                                    filter={appliedFilter}
                                    currency={CURRENCY.AE}
                                    allFilters={allFilters}
                                />
                            </li>
                        ));
                })
            }
        </React.Fragment>
    );

    return (
        <Fragment>
            {(!!appliedFilters.length || selectedSort.key !== "releavance" || searchQuery) && renderTags()}
        </Fragment>
    );
};

AppliedFiltersV2.propTypes = {
    allFilters: PropTypes.object,
    history: PropTypes.object,
    selectedCityName: PropTypes.string,
    selectedCityCode: PropTypes.string,
    updateFilterOptionDataConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    updateSelectedSuggestionsConnect: PropTypes.func,
    updateAppliedSuggestionsConnect: PropTypes.func,
    appliedFilters: PropTypes.array,
    updateAppliedFiltersConnect: PropTypes.func,
    selectedSort: PropTypes.object,
    location: PropTypes.object,
    urlOptions: PropTypes.object,
    setComparingActiveConnect: PropTypes.func
};

export default AppliedFiltersV2;

export const FilterIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.83333 3.74008C4.38 5.72675 6.66666 8.66675 6.66666 8.66675V12.0001C6.66666 12.7334 7.26666 13.3334 8 13.3334C8.73333 13.3334 9.33333 12.7334 9.33333 12.0001V8.66675C9.33333 8.66675 11.62 5.72675 13.1667 3.74008C13.5067 3.30008 13.1933 2.66675 12.6333 2.66675H3.36C2.80666 2.66675 2.49333 3.30008 2.83333 3.74008Z" fill="#0032A6"/>
</svg>`;

export const SearchIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1640_2215)">
<path d="M12.9166 11.6667H12.2583L12.025 11.4417C13.025 10.275 13.5416 8.68337 13.2583 6.9917C12.8666 4.67503 10.9333 2.82503 8.59997 2.5417C5.07497 2.10837 2.10831 5.07503 2.54164 8.60003C2.82497 10.9334 4.67497 12.8667 6.99164 13.2584C8.68331 13.5417 10.275 13.025 11.4416 12.025L11.6666 12.2584V12.9167L15.2083 16.4584C15.55 16.8 16.1083 16.8 16.45 16.4584C16.7916 16.1167 16.7916 15.5584 16.45 15.2167L12.9166 11.6667ZM7.91664 11.6667C5.84164 11.6667 4.16664 9.9917 4.16664 7.9167C4.16664 5.8417 5.84164 4.1667 7.91664 4.1667C9.99164 4.1667 11.6666 5.8417 11.6666 7.9167C11.6666 9.9917 9.99164 11.6667 7.91664 11.6667Z" fill="#0032A6"/>
</g>
<defs>
<clipPath id="clip0_1640_2215">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;

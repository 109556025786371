import { useState } from "react";
import { NUMBER } from "../constants/app-constants";

const useSwipeDetect = (cb = () => { }, minSwipeDistance = NUMBER.FIFTY) => {
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[NUMBER.ZERO].clientX);
    };

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe || isRightSwipe) {
            cb();
        }
    };

    return { onTouchStart, onTouchMove, onTouchEnd };
};

export default useSwipeDetect;

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchFilters, fetchSavedFilters, updateSSRStatus as updateFilterSSRStatus, updateSelectedOnScreenFilter } from "../filters/actions";
import { fetchUserFeedback, setFeedbackCarDetailContent } from "../feedback/actions";

// Component
import withAELayout from "../layout";
import CarListing from "./component";
import { updateDownloadNudgeStatus, updateLocationPopupShown } from "../../ae.configuration/actions";
import { toggleSnackBar } from "./actions";
import { getStatsigDynamicConfig } from "../config-ssr/actions";

const mapStateToProps = ({
    user: {
        secureToken,
        showDownloadNudge
    },
    config: {
        activeSaleConfig = {},
        superAppConfig
    },
    filters: {
        showAllFilters,
        isSSR: isFilterSSR,
        appliedFilters,
        newFilterSelected,
        savedFilters
    },
    cities: {
        selectedCity
    },
    myBookings: {
        bookings,
        isFetching: fetchingSnackCards,
        snackCardsFetched
    },
    carListing: {
        shouldReloadList,
        showSnackBar,
        metaContent,
        isLoading,
        isFetched,
        error,
        totalCars,
        content,
        config: {
            imageHost
        }
    },
    carDetails: {
        similarCars
    },
    compareCars: {
        totalCars: selectedCarsToCompare,
        isComparingActive
    },
    experiments: { msiteSaveSelectedFilters } = {}
}) => ({
    secureToken,
    showAllFilters,
    isFilterSSR,
    selectedCity,
    shouldReloadList,
    metaContent,
    appliedFilters,
    selectedCarsToCompare,
    isComparingActive,
    isLoading,
    isFetched,
    error,
    totalCars,
    activeSaleConfig,
    content,
    imageHost,
    similarCars,
    bookings,
    fetchingSnackCards,
    snackCardsFetched,
    showSnackBar,
    showDownloadNudge,
    msiteSaveSelectedFilters,
    newFilterSelected,
    savedFilters,
    superAppConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFiltersConnect: fetchFilters,
    updateFilterSSRStatusConnect: updateFilterSSRStatus,
    fetchUserFeedbackConnect: fetchUserFeedback,
    setFeedbackCarDetailContentConnect: setFeedbackCarDetailContent,
    updateSelectedOnScreenFilterConnect: updateSelectedOnScreenFilter,
    updateLocationPopupShownConnect: updateLocationPopupShown,
    toggleSnackBarConnect: toggleSnackBar,
    updateDownloadNudgeStatusConnect: updateDownloadNudgeStatus,
    fetchSavedFiltersConnect: fetchSavedFilters,
    getStatsigDynamicConfigConnect: getStatsigDynamicConfig
}, dispatch);

export default withAELayout(connect(mapStateToProps, mapDispatchToProps)(CarListing), true, true, { headerProps: { showBlueIcon: false, showLocationChange: true, showAppDownload: false, showWishlist: true, wishlistIconColor: "orange_filled" } });

/* eslint-disable no-nested-ternary */
import React, {useEffect} from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import IconCreditCard from "./images/credit-cards.png";

// Constants
import { NUMBER } from "../../../constants/app-constants";
import { STICKY_TABS_VARIANT } from "../car-loan-home/constant";

// Utils
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import getUpdatedAppliedFilters from "../../../utils/filters-v2/get-updated-applied-filters";
import Filters from "../../../utils/filters-v2";
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";

const LoanBudgetCta = ({
    toggleEmi,
    handleLoanRoute = () => {},
    handleToggleChange,
    loanEligibility = {},
    allFilters = {},
    appliedFilters = [],
    selectedCityCode,
    selectedCityName,
    updateFilterOptionDataConnect,
    updateAppliedFiltersConnect,
    reloadCarListConnect
}) => {

    const { maxCarEmi, carAvailable, maxCarPrice } = loanEligibility || {};
    const { customReplace } = useCustomHistoryOperations();

    useEffect(() => {
        if (maxCarEmi && maxCarPrice && carAvailable) {
            trackPlatformCustomEventsAEV2(b2cListingPageEvents.carAffordabilityLoanToggle, { eventLabel: "loan_on" });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleToggleEmiFilterChange = async () => {
        const financeData = {
            filterKey: FILTER_ENTITIES.FINANCE,
            isSelected: !toggleEmi ? true : false,
            isRangeFilter: true,
            rangeValues: { min: NUMBER.ZERO, max: maxCarEmi }
        };
        const emiData = {
            filterKey: FILTER_ENTITIES.PRICE,
            isSelected: !toggleEmi ? true : false,
            isRangeFilter: true,
            rangeValues: { min: NUMBER.ZERO, max: maxCarPrice }
        };
        updateFilterOptionDataConnect(financeData);
        updateFilterOptionDataConnect(emiData);
        const updatedFiltersWithFinance = getUpdatedAppliedFilters(financeData, appliedFilters);
        const updatedFiltersWithFinanceEmi = getUpdatedAppliedFilters(emiData, updatedFiltersWithFinance);
        updateAppliedFiltersConnect();
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { relativeURL } = filters.getListingPayload({
            selectedFilters: updatedFiltersWithFinanceEmi, filters: allFilters
        });
        handleToggleChange();
        reloadCarListConnect(true);
        await customReplace(relativeURL);
        scrollToTop();
    };

    return (
        carAvailable === null ?
            <div styleName={"styles.ctaWrapper"} onClick={() => handleLoanRoute()}>
                <div>
                    <img src={IconCreditCard} alt="" width={24} height={24} />
                </div>
                <p>Filter cars on loan within your budget </p>
                <span styleName={"styles.editLink"}>Check Now</span>
            </div> :
            (carAvailable ?
                <div styleName={"styles.ctaWrapper"}>
                    <div>
                        <img src={IconCreditCard} alt="" width={24} height={24} />
                        </div>
                        <p>Cars within your loan budget <strong>AED {maxCarEmi}</strong></p>
                        <label styleName={"styles.toggle"}>
                            <input type="checkbox" onChange={handleToggleEmiFilterChange} checked={toggleEmi}/>
                            <span styleName={"styles.slider"} />
                        </label>
                </div> :
                <div styleName={"styles.ctaWrapper styles.bgOrange"} onClick={() => handleLoanRoute(STICKY_TABS_VARIANT.LOAN_ESTIMATE)}>
                        <div>
                            <img src={IconCreditCard} alt="" width={24} height={24} />
                        </div>
                        <p>You can buy cars on full payment</p>
                        <span styleName={"styles.editLink"}>Edit Details</span>
                </div>
            )
    );
};

LoanBudgetCta.propTypes = {
    toggleEmi: PropTypes.bool,
    handleLoanRoute: PropTypes.func,
    handleToggleChange: PropTypes.func,
    allFilters: PropTypes.object,
    appliedFilters: PropTypes.array,
    selectedCityCode: PropTypes.string,
    selectedCityName: PropTypes.string,
    loanEligibility: PropTypes.object,
    updateFilterOptionDataConnect: PropTypes.func,
    updateAppliedFiltersConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func
};

export default LoanBudgetCta;

export const b2cFeedbackPageEvents = {
    "starClickTDPopUp": {
        "event": "test_drive_feedback",
        "eventCategory": "Buyer_Feedback",
        "eventAction": "Star_click_TD_pop_up"
    },
    "submitTDFeedback": {
        "event": "test_drive_feedback",
        "eventCategory": "Buyer_Feedback",
        "eventAction": "Submit_TD_feedback"
    },
    "crossTDFeedback": {
        "event": "test_drive_feedback",
        "eventCategory": "Buyer_Feedback",
        "eventAction": "cross_TD_feedback"
    },
    "starClickOrderSummaryTD": {
        "event": "test_drive_feedback",
        "eventCategory": "Buyer_Feedback",
        "eventAction": "Star_click_order_summary_TD",
        "eventLabel": "NA"
    },
    "starClickPostBCFeedback": {
        "event": "custom_event",
        "eventCategory": "Buyer_Feedback",
        "eventAction": "Star_click_Post_BC_Feedback"
    },
    "submitPostBCFeedback": {
        "event": "custom_event",
        "eventCategory": "Buyer_Feedback",
        "eventAction": "Submit_Post_BC_Feedback",
        "eventLabel": "NA"
    },
    "TDPopUpClick": {
        "event": "test_drive_feedback",
        "eventCategory": "Buyer_Feedback",
        "eventAction": "TD_pop_up_click",
        "eventLabel": "NA"
    }
};

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BuyCarBreadCrumb from "./component";
import { reloadCarList } from "../car-listing/actions";
import { clearAllFilters } from "../filters/actions";

const mapStateToProps = ({
    cities: { selectedCity },
    filters: {
        appliedFilters,
        allFilters,
        urlOptions
    },
    carDetails: {
        content
    }
}) => {
    return {
        selectedCity,
        appliedFilters,
        content,
        allFilters,
        urlOptions
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList,
    clearAllFiltersConnect: clearAllFilters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BuyCarBreadCrumb);

import React from "react";
// eslint-disable-next-line no-unused-vars
import { PropTypes } from "prop-types";

// Styles
import styles from "./styles.css";

// Components
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";

const PopupLoader = () => {

    return (
        <Modal isOpen={true} hideOverlay={true}>
            <div styleName={"styles.modalWraper"}>
                <div styleName={"styles.modalHeader"}>
                    <h2>{""}</h2>
                    <CloseButton type="orange" onClickHandler={() => {}} />
                </div>
                <div styleName={"styles.modalBody"}>
                    <div styleName={"styles.downloadCardSkeleton"}>
                        <div className="shimmer" styleName={"styles.downloadCardImage"}/>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

PopupLoader.propTypes = {

};

export default PopupLoader;

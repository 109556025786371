import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

// Components
import PopupLoader from "../popup-loader";
const SortByModalV2 = loadable(() => import("../sort-by-modal-v2"), { fallback: <PopupLoader /> });

// Constants
import { COOKIE_CONSTANTS, NUMBER } from "../../../constants/app-constants";

// Utils
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import saveCookie from "../../../utils/helpers/save-cookie";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { CaretDownIcon, SortIcon } from "./img-constants";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";

const SortListingHeader = ({ ctaText = "" }) => {
    const [showSortByModal, setShowSortByModal] = useState(false);

    const handleShowSortByModal = async () => {
        setShowSortByModal(true);
        await yieldToMainThread(NUMBER.FIVE_HUNDRED);
        saveCookie(COOKIE_CONSTANTS.LISTING_FILTER_NUDGE_SHOWN, true, NUMBER.SEVEN);
        trackPlatformCustomEventsAEV2(b2cListingPageEvents.clickSort);
    };

    const handleHideSortByModal = async () => {
        await yieldToMainThread();
        setShowSortByModal(false);
    };

    return (
        <Fragment>
            <div onClick={handleShowSortByModal} styleName={"styles.sortWrap"}>
                <span styleName={"styles.sortIcon"} dangerouslySetInnerHTML={{__html: SortIcon}} /> {ctaText} <span dangerouslySetInnerHTML={{__html: CaretDownIcon}} />
            </div>
            {showSortByModal && <PopupLoader />}
            {showSortByModal && <SortByModalV2 onClose={handleHideSortByModal} />}
        </Fragment>
    );
};

SortListingHeader.propTypes = {
    ctaText: PropTypes.string
};
export default SortListingHeader;

/* eslint-disable complexity */
import React, { Fragment, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";

//Utils
import { parseHomeBannerData } from "../../../utils/helpers/parse-seasonal-sale";

//Styles
import styles from "./styles.css";

//Images
import ArrowIcon from "./images/cta-arrow.svg";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

//Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { b2cHomePageEvents } from "../../../tracking/msite/b2c-home-page-events";

const HomePageDynamicBanner = ({ topBanner }) => {

    const { additionalData = {}, title = {}, subTitle = {}, ctaInfo = {} } = topBanner || {};
    const {customPush} = useCustomHistoryOperations();

    const {
        color: titleTextColor
    } = title || {};

    const {
        color: subTitleTextColor
    } = subTitle || {};

    const {
        title: ctaText,
        backgroundColor: ctaBackgroundColor,
        textColor: ctaTextColor,
        redirectPath
    } = ctaInfo || {};

    const handleRedirection = async () => {
        await customPush(redirectPath);
        trackPlatformCustomEventsAEV2(b2cHomePageEvents.superAppHpViewAllClick);
    };

    const { title: titleText = "", subTitle: subTitleText = "" } = useMemo(() => parseHomeBannerData(topBanner), [topBanner]) || {};
    const {
        backgroundBannerImage
    } = additionalData || {};

    const ctaInnerStyle = ctaInfo ? { backgroundColor: ctaBackgroundColor, color: ctaTextColor } : {};

    return (
        <Fragment>
            {backgroundBannerImage && <Helmet>
                <link rel="preload" href={backgroundBannerImage} as={"image"} type="image/png" fetchPriority="high" />
            </Helmet>}
            <div styleName={"styles.dynamicBanner"} >
                <img styleName={"styles.bannerBackground"}  src={backgroundBannerImage} />
                <div styleName={"styles.contentWrapper"}>
                    {titleText && <div styleName={"styles.titleText"} style={{ color: titleTextColor }} dangerouslySetInnerHTML={{ __html: titleText }} />}
                    {subTitleText && <div styleName={"styles.descriptionText"} style={{ color: subTitleTextColor }} dangerouslySetInnerHTML={{ __html: subTitleText }} />}
                    {ctaText && <div styleName={"styles.ctaText"} style={ctaInnerStyle} onClick={handleRedirection}>
                        <div>{ctaText}</div>
                        <img src={ArrowIcon} width={15} height={10} alt="arrowIcon" />
                    </div>}
                </div>
            </div>
        </Fragment>
    );
};

HomePageDynamicBanner.propTypes = {
    topBanner: PropTypes.object
};

export default HomePageDynamicBanner;


import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SeasonalSaleListingBanner from "./component";

const mapStateToProps = ({
    user: {
        isLoggedIn
    },
    config: {
        superAppConfig = {}
    }
}) => ({ isLoggedIn, superAppConfig});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SeasonalSaleListingBanner);
